import {createApi} from "@reduxjs/toolkit/query/react";
import {API_ACCOUNTS} from "Models/Account/AccountEndpoints";
import {baseQueryWithReauth} from "Utils/helpers";

export const accountApi = createApi({
    reducerPath: "accountApi",
    baseQuery: baseQueryWithReauth(API_ACCOUNTS.accounts),
    tagTypes: ["Account"],
    endpoints: (builder) => ({
        fetchAccounts: builder.query({
            query: ({page = 1, pageSize = 10, filters = {}}) => {
                const filterParams = new URLSearchParams({
                    page,
                    page_size: pageSize,
                    ...Object.fromEntries(
                        Object.entries(filters).map(([key, value]) => [key, `${value}`])
                    ),
                }).toString();
                return `?${filterParams}`;
            },
            transformResponse: (response) => ({
                data: response.results,
                totalRowCount: response.count,
            }),
            providesTags: (result) =>
                result ?
                    [
                        ...result.data.map(({id}) => ({type: "Account", id})),
                        {type: "Account", id: "LIST"},
                    ] :
                    [{type: "Account", id: "LIST"}],
            keepUnusedDataFor: 60,
        }),
        addAccount: builder.mutation({
            query: (newAccount) => ({
                url: "",
                method: "POST",
                body: newAccount,
            }),
            invalidatesTags: [{type: "Account", id: "LIST"}],
        }),
        updateAccount: builder.mutation({
            query: ({id, ...updatedAccount}) => ({
                url: `/${id}/`,
                method: "PUT",
                body: updatedAccount,
            }),
            invalidatesTags: (result, error, {id}) => [
                {type: "Account", id},
                {type: "Account", id: "LIST"},
            ],
        }),
    }),
});

export const {
    useFetchAccountsQuery,
    useAddAccountMutation,
    useUpdateAccountMutation,
} = accountApi;
