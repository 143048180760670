import React, {useEffect, useState} from "react";
import {fetchSelectionOptions} from "Services/ServicesUtils";
import {API_AUDITS} from "Models/Audit/AuditEndpoints";
import PropTypes from "prop-types";
import {GSPSToolbarSelectField} from "Components/Common/GSPSToolbarSelectField";
import {makeGetRequest} from "Services/ServicesUtils";
import {insertNotification} from "Utils/NotificationsUtils";
import {ToolbarContainer} from "./AuditMapToolbar.styles";
import {Button} from "@mui/material";
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';

const AuditDeletionOptionsList = [
    {key: "deleteIssues", value: 1, display_text: "Delete Items"},
    {key: "deleteLines", value: 2, display_text: "Delete Lines"},
    {key: "deleteAreas", value: 3, display_text: "Delete Areas"},
];

export const AuditMapToolbar = ({
    AnnotationShapeIDMap,
    onSelectAnnotationShape,
    onAuditTypeChange,
    onAuditTypeItemChange,
    currentAnnotationShape,
    handleDeleteSelection,
    onResetAuditTypeItem,
    MapClicked,
    onDropDownReset,
    handleFilterSelection,
    AuditFilterOptionsList,
    updatedAuditFilter,
    resetDeleteDropdown,
}) => {
    const [auditTypes, setAuditTypes] = useState([]);
    const [currentAuditType, setCurrentAuditType] = useState(null);
    const [auditTypeItems, setAuditTypeItems] = useState([]);
    const [currentAuditTypeItem, setCurrentAuditTypeItem] = useState(null);
    const [allAuditItems, setAllAuditItems] = useState([]);

    useEffect(() => {
        fetchAllItems();
        fetchSelectionOptions(API_AUDITS.auditsTypes, setAuditTypes);
    }, []);


    useEffect(() => {
        if (currentAuditType && currentAnnotationShape?.query) {
            filterItems(currentAnnotationShape.query, currentAuditType.value);
        }
    }, [currentAuditType, currentAnnotationShape?.query]);

    useEffect(() => {
        if (currentAuditType) {
            onAuditTypeChange(currentAuditType);
        }
    }, [currentAuditType, onAuditTypeChange]);

    useEffect(() => {
        if (currentAuditTypeItem) {
            onAuditTypeItemChange(currentAuditTypeItem);
        }
    }, [currentAuditTypeItem, onAuditTypeItemChange]);

    useEffect(() => {
        if (MapClicked) {
            setCurrentAuditTypeItem(null);
            setAuditTypeItems([]);
            onResetAuditTypeItem();
            onDropDownReset();
        }
    }, [MapClicked]);

    const fetchAllItems = async () => {
        try {
            const allitems = await makeGetRequest(API_AUDITS.auditsTypesItems);
            setAllAuditItems(allitems);
            return allitems;
        } catch (error) {
            insertNotification("Error", `Error Fetching audit types items. Reason: ${error.message}`, "error");
        }
    };

    const filterItems = (annotationType, auditTypeId) => {
        const type = annotationType === "item" ? "issue" : annotationType;
        const filtered = allAuditItems.filter((item) =>
            item.annotation_type === type && item.audit_type.id === auditTypeId).map((item) => ({
                key: item.id,
                value: item.id,
                display_text: item.name,
            }));
        setAuditTypeItems(filtered);
    };

    return (
        <ToolbarContainer>
            <div className="row py-3 align-items-center">
                <div className="col-lg-1 col-12 mb-4 mb-lg-0">
                    <h1 className="title my-0">
                        Let's Go!
                    </h1>
                </div>
                <div className="col-lg-3 col-6 d-flex justify-content-between align-items-center mb-4 mb-lg-0">
                    {auditTypes &&
                        <GSPSToolbarSelectField
                            defaultValue={{key: "DefaultValue", value: 0, display_text: "Select"}}
                            inputID={"audit_type"}
                            selectableOptions={auditTypes}
                            onChangeCB={(selectedOption) => {
                                if (selectedOption && selectedOption.display_text !== "Select") {
                                    setCurrentAuditType(selectedOption);
                                }
                            }}
                            labelName="1- Audit Type"
                        />}
                </div>
                <div className="col-lg-2 col-6 d-flex justify-content-between align-items-center mb-4 mb-lg-0">
                    <GSPSToolbarSelectField
                        inputID={"annotation_shape"}
                        selectableOptions={
                            Object.values(AnnotationShapeIDMap)?.map(obj => ({
                                key: obj.id,
                                value: obj.id,
                                display_text: obj.query
                            }))
                        }
                        DropDownReset={MapClicked}
                        onChangeCB={(selectedItem) => {
                            if (selectedItem) {
                                onSelectAnnotationShape(Number(selectedItem.value));
                            }
                        }}
                        defaultValue={{key: "DefaultValue", value: 0, display_text: "Select"}}
                        labelName={"2- Item Type"}
                    />
                </div>
                <div className="col-lg-2 col-6 d-flex justify-content-between align-items-center mb-4 mb-lg-0">{auditTypeItems &&
                    <GSPSToolbarSelectField
                        inputID={"audit_type_item"}
                        selectableOptions={auditTypeItems}
                        DropDownReset={MapClicked}
                        onChangeCB={(selectedItem) => {
                            if (selectedItem && selectedItem.display_text !== "Select") {
                                setCurrentAuditTypeItem(selectedItem);
                            } else {
                                setCurrentAuditTypeItem(null);
                                onResetAuditTypeItem();
                            }
                        }}
                        defaultValue={{key: "DefaultValue", value: 0, display_text: "Select"}}
                        disableAutoSorting
                        labelName="3- Audit Item"
                    />
                }</div>
                <div className="col-lg-2 col-6 d-flex justify-content-between align-items-center mb-4 mb-lg-0">
                    <GSPSToolbarSelectField
                        inputID="filter_audits"
                        selectableOptions={AuditFilterOptionsList}
                        onChangeCB={(value) => {
                            handleFilterSelection(value);
                        }}
                        labelName="View"
                        renderedElementType={"filter"}
                        updatedAuditFilter={updatedAuditFilter}
                    />
                </div>
                <div className="col-lg-2 col-12 d-flex justify-content-between align-items-center">
                    <GSPSToolbarSelectField
                        inputID="delete_actions"
                        selectableOptions={AuditDeletionOptionsList}
                        isRequired={true}
                        onChangeCB={(value) =>
                            handleDeleteSelection(Object.values(AuditDeletionOptionsList).filter((obj) => obj.value == value)[0].key)
                        }
                        renderedElementType={"select"}
                        labelName="Delete"
                        DropDownReset={resetDeleteDropdown}
                    />
                    <Button type={"button"} variant={"contained"} className="px-3 d-none" startIcon={<CheckBoxOutlinedIcon />} onClick={(e) => {
                        e.preventDefault()
                    }}>
                        Finish
                    </Button>
                </div>
            </div>
        </ToolbarContainer>
    );
};

AuditMapToolbar.propTypes = {
    AnnotationShapeIDMap: PropTypes.object.isRequired,
    onSelectAnnotationShape: PropTypes.func.isRequired,
    currentAnnotationShape: PropTypes.object,
    onAuditTypeChange: PropTypes.func.isRequired,
    onAuditTypeItemChange: PropTypes.func.isRequired,
    onResetAuditTypeItem: PropTypes.func.isRequired,
    handleDeleteSelection: PropTypes.func,
    MapClicked: PropTypes.bool,
    resetDeleteDropdown: PropTypes.bool,
    onDropDownReset: PropTypes.func,
    handleFilterSelection: PropTypes.func,
    AuditFilterOptionsList: PropTypes.array,
    updatedAuditFilter: PropTypes.object.isRequired
};
