import React from "react";
import PropTypes from "prop-types";
import {AuditTable} from "Pages/Audits/AuditTable";

export const AuditMapSelectedAreas = ({
    selectedAreas,
    updateElementImages,
    updateElementVideos,
    isViewOnly = true,
    updateFieldCB = null,
    deleteAreaCall,
    editAreaCall,
}) => {
    const IndexedAreas = selectedAreas.map((area, index) => {
        return {
            ...area,
            index: index + 1,
        };
    });

    return (
        <AuditTable
            headerName={"Areas"}
            dimensionHeader={"Dimension (ft²)"}
            isViewOnly={isViewOnly}
            items={IndexedAreas}
            elementType={"area"}
            onDelete={deleteAreaCall}
            onEdit={editAreaCall}
        />
    );
};

AuditMapSelectedAreas.propTypes = {
    selectedAreas: PropTypes.array.isRequired,
    updateElementImages: PropTypes.func.isRequired,
    updateElementVideos: PropTypes.func.isRequired,
    isViewOnly: PropTypes.bool,
    updateFieldCB: PropTypes.func,
    deleteAreaCall: PropTypes.func,
    editAreaCall: PropTypes.func,
};
