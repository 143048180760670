import styled from "styled-components";
import introBg from './map_screenshot.png';

export const AuditsIntroSectionContainer = styled.div`
    position: relative;
    background-image: url(${introBg});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    z-index: 1;
    padding-bottom: 18px;
    margin-bottom: -51px;

    &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(251, 251, 251, 0.9);
        pointer-events: none;
        z-index: -1;
    }

    & .title{
        color: ${({theme}) => theme.color.textOnBg};
        font-size: 40px;
        font-Weight: 600;
        letter-spacing: -1px;
    }
    
    & .breakdown .title{
        font-size: 16px;
        font-Weight: 400;
        letter-spacing: 1px;
        opacity: 0.5;
    }

    & .breakdown .updatedNumber{
        color: ${({theme}) => theme.color.textOnBg};
        font-size: 56px;
        font-weight: 700;
        letter-spacing: -1.5px;
        line-height: 62px;
    }
    & .breakdown .description{
        color: ${({theme}) => theme.color.textOnBg};
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
    }

    & .chart svg > g:first-of-type{
        transform: scale(1.5);
        transform-origin: left;
    }

    & .chart .css-1mhcdve-MuiPieArc-root{
        stroke: none;
    }
`