import styled from "styled-components";

export const DatePickerContainer = styled.div`
    width: 100%;
    .MuiFormControl-root {
        width: 100%;
    }
    & .Mui-focused fieldset {
        border-color: ${({theme}) => theme.color.info} !important;
    }
        
    & .MuiFormLabel-root.Mui-focused {
        color: ${({theme}) => theme.color.info} !important;
    }
    
    & .Mui-focused input::selection {
        color: ${({theme}) => theme.color.dark};
        background-color: ${({theme}) => theme.color.info};
    }
`