import {GSPSCheckBox} from "Components/Common/GSPSCheckBox"

export const AuditCheckBoxes = ({onCheckBoxChange, audit}) => {

    const handleCheckBoxChange = (checked, id) => {
        onCheckBoxChange(checked, id);
    }

    return (
        <>
            <GSPSCheckBox
                label="Make description internal only"
                id="is_description_internal_only"
                onChangeCB={(checked, id) => {handleCheckBoxChange(checked, id)}}
                defaultValue={audit?.is_description_internal_only}
            />
            <GSPSCheckBox
                label="Make notes internal only"
                id="is_notes_internal_only"
                onChangeCB={(checked, id) => {handleCheckBoxChange(checked, id)}}
                defaultValue={audit?.is_notes_internal_only}
            />
            <GSPSCheckBox
                label="Make dimensions internal only"
                id="is_dimensions_internal_only"
                onChangeCB={(checked, id) => {handleCheckBoxChange(checked, id)}}
                defaultValue={audit?.is_dimensions_internal_only}
            />
        </>
    )
}