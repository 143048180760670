import {createApi} from "@reduxjs/toolkit/query/react";
import {API_AUDITS} from "Models/Audit/AuditEndpoints";
import {baseQueryWithReauth} from "Utils/helpers";

export const auditApi = createApi({
    reducerPath: "auditApi",
    baseQuery: baseQueryWithReauth(API_AUDITS.audits),
    tagTypes: ["Audit", "ArchivedAudit"],
    endpoints: (builder) => ({
        fetchAudits: builder.query({
            query: ({page = 1, pageSize = 10, active = true, filters = {}}) => {
                const filterParams = new URLSearchParams({
                    page,
                    page_size: pageSize,
                    active,
                    ...Object.fromEntries(
                        Object.entries(filters).map(([key, value]) => [key, `${value}`])
                    ),
                }).toString();
                return `?${filterParams}`;
            },
            transformResponse: (response) => ({
                data: response.results,
                totalRowCount: response.count,
            }),
            providesTags: (result) =>
                result ? 
                    [...result.data.map(({id}) => ({type: "Audit", id})), "Audit"] :
                    ["Audit"],
            keepUnusedDataFor: 10,
        }),
        fetchArchivedAudits: builder.query({
            query: ({page = 1, pageSize = 10, filters = {}}) => {
                const filterParams = new URLSearchParams({
                    page,
                    page_size: pageSize,
                    active: false,
                    ...Object.fromEntries(
                        Object.entries(filters).map(([key, value]) => [key, `${value}`])
                    ),
                }).toString();
                return `?${filterParams}`;
            },
            transformResponse: (response) => ({
                data: response.results,
                totalRowCount: response.count,
            }),
            providesTags: (result) =>
                result ? 
                    [...result.data.map(({id}) => ({type: "ArchivedAudit", id})), "ArchivedAudit"] :
                    ["ArchivedAudit"],
            keepUnusedDataFor: 10,
        }),
        archiveAudit: builder.mutation({
            query: (auditId) => ({
                url: `${auditId}/`,
                method: "PATCH",
                body: {active: false},
            }),
            invalidatesTags: (result, error, auditId) => [
                {type: "Audit", id: auditId},
                "Audit",
                "ArchivedAudit",
            ],
        }),
        unarchiveAudit: builder.mutation({
            query: (auditId) => ({
                url: `${auditId}/`,
                method: "PATCH",
                body: {active: true},
            }),
            invalidatesTags: (result, error, auditId) => [
                {type: "ArchivedAudit", id: auditId},
                "Audit",
                "ArchivedAudit",
            ],
        }),
        deleteAudit: builder.mutation({
            query: (auditId) => ({
                url: `${auditId}/`,
                method: "DELETE",
            }),
            invalidatesTags: (result, error, auditId) => [
                {type: "Audit", id: auditId},
                {type: "ArchivedAudit", id: auditId},
            ],
        }),
        cloneAudit: builder.mutation({
            query: (auditId) => ({
                url: `${auditId}/clone/`,
                method: "POST",
            }),
            invalidatesTags: ["Audit"],
        }),
        addAudit: builder.mutation({
            query: (newAudit) => ({
                url: "",
                method: "POST",
                body: newAudit,
            }),
            transformResponse: (response) => response,
            invalidatesTags: ["Audit"],
        }),
        updateAudit: builder.mutation({
            query: ({id, updatedAudit}) => ({
                url: `${id}/`,
                method: "PUT",
                body: updatedAudit,
            }),
            transformResponse: (response) => response,
            invalidatesTags: (result, error, {id}) => [{type: "Audit", id}],
        }),
    }),
});

export const {
    useFetchAuditsQuery,
    useFetchArchivedAuditsQuery,
    useArchiveAuditMutation,
    useUnarchiveAuditMutation,
    useDeleteAuditMutation,
    useCloneAuditMutation,
    useAddAuditMutation,
    useUpdateAuditMutation,
} = auditApi;
