import React, {useEffect, useRef, useState} from "react";
import {Form} from "react-bootstrap";
import {useForm} from "react-hook-form";
import {makeGetRequest, makePatchRequest} from "Services/ServicesUtils";
import {API_AUDITS} from "Models/Audit/AuditEndpoints";
import {insertNotification} from "Utils/NotificationsUtils";
import {GSPSLabelledInput} from "Components/Common/GSPSLabelledInput";
import {GSPSTextArea} from "Components/Common/GSPSTextAreaInput";
import {useNavigate} from "react-router-dom";
import PropTypes from "prop-types";
import {Button} from "@mui/material";
import {InfoForm} from "./InfoForm.styles";
import {useUpdateAuditMutation} from "api/AuditTableAPI";

export const AuditorInformation = ({
    audit,
    user,
    isReadOnly,
}) => {
    const {handleSubmit, register, setValue, formState: {errors}} = useForm();
    const [selectedAuditor, setSelectedAuditor] = useState();
    const [auditStatuses, setAuditStatuses] = useState([]);
    const [submitText, setSubmitText] = useState("Submit");
    const navigate = useNavigate();
    const autosaveNotesTimeout = useRef(null);
    const [notes, setNotes] = useState(audit?.auditor_additional_notes ?? "");
    useEffect(() => {
        if (!isReadOnly) {
            makeGetRequest(`${API_AUDITS.auditStatuses}`).then(
                (res) => {
                    setAuditStatuses(res.results);
                },
            );
        }
    }, []);

    useEffect(() => {
        setNotes(audit?.auditor_additional_notes ?? "");
        setSelectedAuditor(audit?.auditor ?? user);
    }, [audit]);
    const [updateAudit] = useUpdateAuditMutation();

    const onSubmit = async () => {
        try {
            const updatedAudit = {
                ...audit,
                auditor_id: selectedAuditor?.id,
                auditor_additional_notes: notes,
                audit_status_id: auditStatuses?.find((status) => status.name === "In Review")?.id,
                audit_type_id: audit.audit_type.id,
                location: {
                    ...audit.location,
                    account_id: audit.location.account.id,
                },
                lines: audit.audit_lines,
                areas: audit.audit_areas,
            };

            const jsonRes = await updateAudit({id: audit.id, updatedAudit}).unwrap();
            insertNotification("Success", `Audit ${jsonRes.id} has been updated.`, "success");
            setSubmitText("In Review");
            navigate("/dashboard");
        } catch (e) {
            insertNotification("Error", `Can't update audit: ${e.message || e}`, "error");
        }
    };

    const updateNotes = async (notes) => {
        clearTimeout(autosaveNotesTimeout.current);
        autosaveNotesTimeout.current = setTimeout(async () => {
            if (audit && notes != audit?.auditor_additional_notes && !isReadOnly) {
                const updatedAudit = {
                    auditor_additional_notes: notes,
                };
                makePatchRequest(`${API_AUDITS.audits}${audit.id}/`, updatedAudit);
            }
        }, 1000);
    };

    return (
        <InfoForm>
            <div className="container">
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <h1 className="title">
                        Auditor Information
                    </h1>
                    <div className="row">
                        <div className="col-md-8 col-12 ps-4 d-flex flex-column justify-content-between">
                            <div className="row">
                                <div className="col-md-5 col-12 label d-flex align-items-center">
                                    Reviewer Name
                                </div>
                                <div className="col">
                                    <GSPSLabelledInput
                                        controlType={"input"}
                                        id={"auditor"}
                                        labelName={"Auditor"}
                                        errors={errors}
                                        register={register}
                                        isDisabled={true}
                                        value={selectedAuditor?.full_name ?? audit?.auditor?.full_name}
                                        hiddenLabel
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-5 col-12 label d-flex align-items-center">
                                    Phone number
                                </div>
                                <div className="col">
                                    <GSPSLabelledInput
                                        controlType={"input"}
                                        id={"auditorPhone"}
                                        labelName={"Phone"}
                                        errors={errors}
                                        register={register}
                                        isDisabled={true}
                                        value={selectedAuditor?.mobile_phone}
                                        hiddenLabel
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-5 col-12 label d-flex align-items-center">
                                    Email
                                </div>
                                <div className="col">
                                    <GSPSLabelledInput
                                        controlType={"input"}
                                        id={"auditorEmail"}
                                        labelName={"Email"}
                                        errors={errors}
                                        isDisabled={true}
                                        register={register}
                                        value={selectedAuditor?.email}
                                        hiddenLabel
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 col-12">
                            <div className="label mb-2">
                                Additional Notes
                            </div>
                            <GSPSTextArea
                                value={audit?.auditor_additional_notes}
                                labelName={"Additional Notes"}
                                id={"additionalNotes"}
                                controlType={"textarea"}
                                isRequired={false}
                                register={register}
                                isDisabled={isReadOnly}
                                rows={7}
                                errors={errors}
                                setValue={setValue}
                                charLimit={700}
                                setDescription={setNotes}
                                autoSave={true}
                                updateOnChange={updateNotes}
                                hiddenCharLimit
                                hiddenLabel
                            />
                        </div>
                    </div>
                    {!isReadOnly && (
                        <div className="float-end">
                            <Button type="submit" onClick={onSubmit} variant={"contained"} className="mt-3 float-end">
                                {submitText}
                            </Button>
                        </div>
                    )}
                </Form>
            </div>
        </InfoForm>
    );
};

AuditorInformation.propTypes = {
    audit: PropTypes.object,
    user: PropTypes.object,
    isReadOnly: PropTypes.bool,
};
