import React from "react";
import {Col} from "react-bootstrap";
import PropTypes from "prop-types";
import {GSPSCancelButtonStyle} from "Components/Common/GSPSCancelButton.styles";

export const GSPSCancelButton = ({
    buttonText,
    controlSize,
    offset,
    onClick,
    colClassName,
    padding,
    isLoading = false,
    isDisabled = false,
    form,
}) => {
    return <>
        <GSPSCancelButtonStyle disabled={isDisabled} onClick={onClick} form={form}>
            {buttonText}
            {isLoading &&
                    <div className="spinner-border spinner-border-sm" role="status" />
            }
        </GSPSCancelButtonStyle>
    </>;
};

GSPSCancelButton.propTypes = {
    buttonText: PropTypes.string.isRequired,
    controlSize: PropTypes.number,
    offset: PropTypes.number,
    onClick: PropTypes.func,
    colClassName: PropTypes.string,
    isLoading: PropTypes.bool,
    isDisabled: PropTypes.bool,
    secondaryBtn: PropTypes.bool,
    padding: PropTypes.number,
    form: PropTypes.string,
};
