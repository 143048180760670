import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {GSPSLabelledInput} from "Components/Common/GSPSLabelledInput";
import {Col, Container, Row} from "react-bootstrap";
import {GSPSLabelledSelectField} from "Components/Common/GSPSLabelledSelectField";
import {GSPSEnableDisableSwitch} from "Components/Common/GSPSEnableDisableSwitch";
import {AddressForm} from "Components/Common/AddressForm";
import {fetchSelectionOptions} from "Services/ServicesUtils";
import {API_ACCOUNTS} from "Models/Account/AccountEndpoints";
import {useCurrentUser} from "Models/User/useCurrentUser";
import {API_LOCATIONS} from "Models/Location/LocationEndpoints";
import {GSPSInputContainer} from "Components/Common/GSPSStyledContainers.styles";

export const LocationForm = ({
    location,
    register,
    errors,
    setValue,
    labelSize = 5,
    controlSize = 6,
}) => {
    const [statusTypes, setStatusTypes] = useState();
    const [accounts, setAccounts] = useState();
    const currentUser = useCurrentUser();

    useEffect(() => {
        fetchSelectionOptions(API_ACCOUNTS.accountsDropdown, setAccounts);
        fetchSelectionOptions(API_LOCATIONS.statusTypes, setStatusTypes);
    }, [currentUser]);

    return (
        <>
            <Container>
                {accounts && statusTypes ? (
                    <Row>
                        <Col xs={10} md={6}>
                            <>
                                <GSPSInputContainer>
                                    <GSPSLabelledSelectField
                                        labelName={"Account"}
                                        inputID={"location_account_id"}
                                        selectableOptions={accounts}
                                        defaultValue={location?.account?.id}
                                        isRequired={true}
                                        register={register}
                                        errors={errors}
                                    />
                                </GSPSInputContainer>
                                <GSPSInputContainer>
                                    <AddressForm
                                        id="address"
                                        caller={location}
                                        register={register}
                                        errors={errors}
                                        setValue={setValue}
                                    />
                                </GSPSInputContainer>
                                <GSPSInputContainer>
                                    <GSPSLabelledSelectField
                                        labelName={"Property Type"}
                                        inputID={"status_type"}
                                        defaultValue={location?.status_type}
                                        selectableOptions={statusTypes}
                                        register={register}
                                        errors={errors}
                                    />
                                </GSPSInputContainer>
                            </>
                        </Col>

                        <Col xs={10} md={6}>
                            <>
                                <GSPSInputContainer>
                                    <GSPSLabelledInput
                                        id="location"
                                        controlType={"input"}
                                        value={location?.location}
                                        labelName={"Description"}
                                        register={register}
                                        errors={errors} />
                                </GSPSInputContainer>
                                <GSPSInputContainer>
                                    <GSPSLabelledInput
                                        id="project_id"
                                        controlType={"input"}
                                        value={location?.project_id}
                                        labelName={"Project ID"}
                                        register={register}
                                        errors={errors} />
                                </GSPSInputContainer>

                                {location && (
                                    <GSPSInputContainer>
                                        <GSPSEnableDisableSwitch
                                            inputId={"is_active"}
                                            defaultValue={location?.is_active}
                                            register={register}
                                        />
                                    </GSPSInputContainer>
                                )}
                            </>
                        </Col>
                    </Row>
                ) : (
                    "Still loading ..."
                )}
            </Container>
        </>
    );

};

LocationForm.propTypes = {
    errors: PropTypes.object.isRequired,
    register: PropTypes.func.isRequired,
    location: PropTypes.object,
    labelSize: PropTypes.number,
    controlSize: PropTypes.number,
    setImageValue: PropTypes.func,
};
