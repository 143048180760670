import {Form} from "react-bootstrap";
import styled from "styled-components";

export const GSPSToolbarSelectFieldContainer = styled.div`
    width: 100%;
    text-transform: capitalize;
    & input{
        font-size: 14px;
        font-weight: 400;
    }
    & fieldset{
        border: none
    }
    & label{
        font-size: 14px;
        font-weight: 400;
        color: ${({theme}) => theme.color.secondaryText} !important;
    }	
`;

export const GSPSToolbarSelectFieldStyle = styled(Form.Select)`
    border-radius: 0.625rem;
    width: 10rem;
    padding: 0.75rem 1rem;
    margin: 0.2rem;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1rem;
    min-width: 6rem;
`;
