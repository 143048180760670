import React, {useState, useEffect} from "react";
import {Col} from "react-bootstrap";
import PropTypes from "prop-types";
import {StyledLogoUploadContainer} from "./GSPSStyledContainers.styles";
import {StyledRemoveImageLabel, StyledUploadImageLabel} from "./GSPSLabeledInput.styles";

const FileSelectionStatus = {
    NONE_SELECTED: 0,
    INVALID_SELECTED: 1,
    VALID_SELECTION: 2,
};

function getMediaIconName(mediaType) {
    if (mediaType === "image") {
        return "CardImage";
    }
    return "CameraVideo";
}

function getMediaViewer(mediaType, src, sizeWPx, sizeHPx, includeControls = false) {
    if (mediaType === "image") {
        return <><img src={src}
            style={{width: sizeWPx + "px", height: sizeHPx + "px"}}>
        </img>
        </>;
    } else if (mediaType === "video") {
        return <video src={src} controls={includeControls}
            style={{width: sizeWPx + "px", height: sizeHPx + "px"}}>
        </video>;
    }
    console.warn(`Unknown Media type ${mediaType} received in MediaSelector component`);
    return <></>;
}

function getMediaTypesFileFormats(mediaType) {
    if (mediaType === "image") {
        return "image/*,.heic;";
    } else if (mediaType === "video") {
        return "video/*;";
    }
    console.warn(`Unknown Media type ${mediaType} received in MediaSelector component`);
    return "*";
}

export const GSPSMediaLogoSelector = ({
    id,
    controlSize,
    imageContainerWidthPx,
    imageContainerHeightPx,
    setValue,
    currentImageSrc,
    mediaType,
    isViewOnly = false,
    handleDeleteImage,
    setHasLogo,
}) => {
    // States
    const [fileSelectionStatus, setfileSelectionStatus] = useState(FileSelectionStatus.NONE_SELECTED);
    const [fileContent, setFileContent] = useState(undefined);
    const [currentImg, setCurrentImg] = useState(null);

    useEffect(() => {
        setCurrentImg(currentImageSrc);
    }, [currentImageSrc]);

    // Functions
    const handleChangeImage = (event) => {
        event.preventDefault();
        try {
            if (event.target.files[0]) {
                setHasLogo(true);
                const objectUrl = URL.createObjectURL(event.target.files[0]);
                setFileContent(objectUrl);
                setfileSelectionStatus(FileSelectionStatus.VALID_SELECTION);
                setCurrentImg(objectUrl);
                if (setValue) setValue(event.target.name, event.target.files);
            }
        } catch (e) {
            setfileSelectionStatus(FileSelectionStatus.INVALID_SELECTED);
        }
    };

    return <>
        <Col sm={controlSize} className="position-relative d-inline-block">
            {(currentImageSrc || fileContent) && currentImg &&
                <label
                    style={{
                        width: imageContainerWidthPx + "px",
                        height: imageContainerHeightPx + "px",
                        cursor: "default",
                    }}>
                    {fileSelectionStatus === FileSelectionStatus.NONE_SELECTED && currentImageSrc &&
                        <div>
                            {getMediaViewer(mediaType, currentImageSrc, imageContainerWidthPx, imageContainerHeightPx)}
                        </div>
                    }
                    {fileSelectionStatus === FileSelectionStatus.VALID_SELECTION &&
                        getMediaViewer(mediaType, fileContent, imageContainerWidthPx, imageContainerHeightPx)}
                </label>
            }
            {!isViewOnly &&
                <input style={{display: "none", visibility: "hidden", width: "1px"}} accept={getMediaTypesFileFormats(mediaType)}
                    id={id}
                    type={"file"}
                    onChange={handleChangeImage}
                    name={id}
                />}
            <StyledLogoUploadContainer>
                <StyledUploadImageLabel htmlFor={id}>
                    Upload Image
                </StyledUploadImageLabel>
                {!isViewOnly && currentImg && (
                    <StyledRemoveImageLabel
                        onClick={() => {
                            handleDeleteImage();
                            setCurrentImg(null);
                        }}
                    >
                        Remove Image
                    </StyledRemoveImageLabel>
                )}
            </StyledLogoUploadContainer>
        </Col>
    </>;
};


GSPSMediaLogoSelector.propTypes = {
    id: PropTypes.string.isRequired,
    controlSize: PropTypes.number,
    imageContainerWidthPx: PropTypes.number.isRequired,
    imageContainerHeightPx: PropTypes.number.isRequired,
    setValue: PropTypes.func,
    handleDeleteImage: PropTypes.func,
    setHasLogo: PropTypes.bool,
    currentImageSrc: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object,
    ],
    ),
    mediaType: PropTypes.oneOf([
        "image",
        "video",
    ]),
    isViewOnly: PropTypes.bool,
};
