import React, {useEffect, useState} from "react";
import {GSPSLabelledInput} from "Components/Common/GSPSLabelledInput";
import {GSPSFormImageInput} from "Components/Common/GSPSFormImageInput";
import {
    fetchSelectionOptions,
    makeDeleteRequest,
    makeFormDataUpdateRequest,
    makeGetRequest,
    makeUpdateRequest,
} from "Services/ServicesUtils";
import {API_SUBSCRIBER} from "Models/Subscriber/SubscriberEndpoints";
import {Col, Container, Form, Row} from "react-bootstrap";
import {useParams} from "react-router-dom";
import {useForm} from "react-hook-form";
import {GSPSSubmitButton} from "Components/Common/GSPSSubmitButton";
import {GSPSCancelButton} from "Components/Common/GSPSCancelButton";
import {GSPSLabelledSelectField} from "Components/Common/GSPSLabelledSelectField";
import {insertNotification} from "Utils/NotificationsUtils";
import {AddressForm} from "Components/Common/AddressForm";
import {UserPermissions} from "Models/User/UserUtils";
import {SubscriberStyledContainer, SubscriberStyledHeader, BtnsContainer, StyledSubscriberRow, SubscriberTitle, SubscriberBodyContainer} from "./Subscriber.styles";
import {useNavigate} from "react-router-dom";
import GSPSBreadCrumbs from "../../Components/Common/GSPSBreadCrumbs";

export const Subscriber = () => {
    const {handleSubmit, register, setValue, formState: {isDirty, touched, errors}} = useForm();
    const {subscriberID} = useParams();
    const [subscriber, setSubscriber] = useState();
    const [defaultUnits, setDefaultUnits] = useState();
    const [industries, setIndustries] = useState();
    const [hasLogo, setHasLogo] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const canManageUsers = UserPermissions.useIsAdmin();
    const navigate = useNavigate();

    useEffect(() => {
        makeGetRequest(`${API_SUBSCRIBER.subscribers}${subscriberID}/`).then(
            (res) => {
                setSubscriber(res);
                setHasLogo(res.logo !== null);
            },
        );
        fetchSelectionOptions(API_SUBSCRIBER.defaultUnits, setDefaultUnits, "name");
        fetchSelectionOptions(API_SUBSCRIBER.industries, setIndustries, "name");
    }, []);

    const onSubmit = async (values) => {
        try {
            setIsSubmitting(true);
            await submitImage(values);
            await submitForm(values);
        } catch (e) {
            console.error("Something is wrong with saving Subscriber details");
        } finally {
            setIsSubmitting(false);
        }
    };

    const submitForm = async (values) => {
        const body = {
            id: {subscriber},
            name: values["subscriber_name"],
            address: {
                apt_num: values.address.apt_num,
                street_num: values.address.street_num,
                street_name: values.address.street_name,
                city: values.address.city,
                state: values.address.state,
                zip: values.address.zip,
            },
            website: values["website"],
            phone: values["phone"],
            default_unit: values["default_unit"],
            industry: values["industry"],
        };

        makeUpdateRequest(`${API_SUBSCRIBER.subscribers}${subscriberID}/`, body)
            .then((jsonRes) => {
                insertNotification("Success",
                    `Subscriber ${jsonRes.name} has been updated ..`,
                    "success");
                return jsonRes;
            }).catch((error) => {
                error.then((errorMessage) => {
                    const failureReason = JSON.stringify(errorMessage);
                    insertNotification("Error", `can't update subscriber ${failureReason}`, "error");
                });
            });
    };

    const submitImage = async (values) => {
        if ((hasLogo)) {
            const formData = new FormData();
            formData.append("logo", values.logo[0]);
            return makeFormDataUpdateRequest(API_SUBSCRIBER.subscriberLogo(subscriberID), formData)
            .catch((e)=>{
                console.log(e);
            });
        }
        else {
            return makeDeleteRequest(API_SUBSCRIBER.subscriberLogo(subscriberID))
            .catch((e)=>{
                console.log(e);
            });
        }
    };

    return (
        <>
            <GSPSBreadCrumbs staticCrumb="Settings - Subscriber Information" />
            {(canManageUsers) ?
                <>
                    <SubscriberStyledContainer>
                        <SubscriberStyledHeader>
                            <SubscriberTitle>
                                Settings - Subscriber Information
                            </SubscriberTitle>
                            <BtnsContainer>
                                <GSPSCancelButton
                                    controlSize={0}
                                    offset={0}
                                    buttonText={"Cancel"}
                                    secondaryBtn={true}
                                    onClick={() => navigate("/dashboard")}
                                />
                                <GSPSSubmitButton isDisabled={(!isDirty && touched) ||
                                    errors.zip?.message.length > 0 || isSubmitting}
                                    isLoading={isSubmitting}
                                    controlSize={0}
                                    offset={0}
                                    padding={0}
                                    buttonText={"Save"}
                                    onClick={handleSubmit(onSubmit)} />
                            </BtnsContainer>
                        </SubscriberStyledHeader>
                        {subscriber &&
                            <>
                                <SubscriberBodyContainer>
                                    <Form onSubmit={handleSubmit(onSubmit)}>
                                        <StyledSubscriberRow>
                                            <Col xs={10} md={5}>
                                                <GSPSLabelledInput
                                                    id="subscriber_name"
                                                    controlType={"input"}
                                                    value={subscriber.name}
                                                    labelName={"Name"}
                                                    isRequired={true}
                                                    register={register}
                                                    errors={errors} />

                                                <AddressForm
                                                    id="address"
                                                    register={register}
                                                    errors={errors}
                                                    caller={subscriber}
                                                    setValue={setValue}
                                                />

                                                <GSPSLabelledInput
                                                    id="website"
                                                    controlType={"input"}
                                                    value={subscriber.website}
                                                    labelName={"Website"}
                                                    register={register}
                                                    errors={errors} />

                                                <GSPSLabelledInput
                                                    id="phone"
                                                    controlType={"input"}
                                                    value={subscriber.phone}
                                                    labelName={"Phone"}
                                                    register={register}
                                                    errors={errors}
                                                    isPhone={true} />

                                                {industries &&
                                                    <GSPSLabelledSelectField
                                                        labelName={"Industry"}
                                                        defaultValue={subscriber.industry}
                                                        inputID={`industry`}
                                                        selectableOptions={industries}
                                                        register={register}
                                                        errors={errors}
                                                    />}
                                            </Col>

                                            <Col xs={10} md={5}>
                                                {defaultUnits &&
                                                    <GSPSLabelledSelectField
                                                        labelName={"Default Units"}
                                                        defaultValue={subscriber.default_unit}
                                                        inputID={`default_unit`}
                                                        selectableOptions={defaultUnits}
                                                        register={register}
                                                        errors={errors}
                                                        isRequired={true}
                                                    />
                                                }
                                                <GSPSFormImageInput
                                                    id={"logo"}
                                                    labelName={"Add Logo"}
                                                    labelSize={5}
                                                    controlSize={5}
                                                    imageContainerWidthPx={290}
                                                    imageContainerHeightPx={175}
                                                    setValue={setValue}
                                                    currentImageSrc={subscriber.logo}
                                                    handleDeleteImage={() => {setHasLogo(false)}}
                                                    setHasLogo={setHasLogo}
                                                />

                                            </Col>
                                        </StyledSubscriberRow>
                                    </Form>
                                </SubscriberBodyContainer>
                            </>
                        }
                    </SubscriberStyledContainer>
                </>
                : (<h2>Access Denied</h2>)}
        </>
    );
}
    ;
