import styled from "styled-components";

export const HeaderTitle = styled.h1`
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 28px;
    margin-bottom : 0px !important;
    color: #121212;
`;

export const HeaderDescription = styled.p`
    font-family: 'Roboto Flex';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    margin-top : 12px;
    color: #121212;
`;