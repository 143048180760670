import styled from "styled-components";

export const InfoForm = styled.div`
    .label{
        color: ${({theme}) => theme.color.textOnBg};
        font-size: 14px;
        font-weight: 400;
    }
    input,
    .MuiAutocomplete-inputRoot{
        background: ${({theme}) => theme.color.secondaryBg};
        border: 1px solid ${({theme}) => theme.color.border};
    }
    .MuiInputBase-root{
        padding-top: 0;
        padding-bottom: 0;
    }
    input:hover,
    .MuiInputBase-root:hover .MuiOutlinedInput-notchedOutline,
    input:focus,
    .MuiInputBase-root:focus{
        border: 1px solid ${({theme}) => theme.color.info};
    }
    .MuiInputBase-root input,
    fieldset,
    .MuiInputBase-root input:focus,
    .MuiInputBase-root input:hover{
        border: none;
    }
`