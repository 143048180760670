export const AvailableThemes = {
    light: "lightTheme",
    dark: "darkTheme",
};

const defaultTheme = {
    color: {
        primary: "#8CFAD0",
        secondary: "#1E554F",
        cancel: "transparent",
        success: "#14C01B",
        info: "#5DFDCD",
        warning: "#FFD80C",
        danger: "#EA1414",
        light: "#FFFFFF",
        dark: "#282832",
        textOnPrimary: "black",
        textOnWhite: "black",
        primaryHover: "#70C8A6",
        headerFooterlight: "#EBEFEE",
        headerFooterdark: "#414141",
        headerFooterBorderlight: "#E8E7F5",
        headerFooterBorderdark: "#595959",
        switchEnabled: "#8CFAD0",
        switchDisabled: "#E16E75",

    },
};

const darkTheme = {
    border: "white",
    textOnBg: "white",
    accentTextOnBg: defaultTheme.color.primary,
    bodyBackground: "#18181F",
    secondaryBg: "#18181F",
    secondaryText: "#FFFFFF",
    navBg: "#121212",
    bodyBackground: defaultTheme.color.dark,
    headerFooterBackground: defaultTheme.color.headerFooterdark,
    SwitchKnobColor: "#282832",
    labelText: "#FFFFFF",
};

const lightTheme = {
    border: "#E8E7F5",
    textOnBg: "#121212",
    accentTextOnBg: "black",
    bodyBackground: "#F8FAF9",
    secondaryBg: "#FFFFFF",
    secondaryText: "#717171",
    navBg: "#00131A",
    bodyBackground: "white",
    headerFooterBackground: defaultTheme.color.headerFooterlight,
    SwitchKnobColor: "#FFFFFF",
    labelText: "#121212",
};

const theme = {
    darkTheme: {
        color: {
            ...defaultTheme.color,
            ...darkTheme,
        },
    },
    lightTheme: {
        color: {
            ...defaultTheme.color,
            ...lightTheme,
        },
    },
};

export default theme;
