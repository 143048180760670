import React, {useState, useEffect} from "react";
import PropTypes from "prop-types";
import {QuestionsContainer} from "./AuditQuestions.styles";
import {Divider} from "@mui/material";
import {AuditCheckBoxes} from "./AuditCheckBoxes";
import {makePatchRequest} from "Services/ServicesUtils";
import {API_AUDITS} from "Models/Audit/AuditEndpoints";

export const AuditQuestions = ({
    questions,
    auditorAnswers,
    handleBlur,
    handleChange,
    isReadOnly,
    audit,
}) => {
    const [charCounts, setCharCounts] = useState([]);
    const charLimit = 200;

    useEffect(() => {
        const lengths = auditorAnswers.map((answer) => answer?.length || 0);
        setCharCounts(lengths);
    }, [auditorAnswers]);

    function handleInputChange(index, textValue) {
        if (!isReadOnly) {
            handleChange(index, textValue);
        }
    }

    function handleInputBlur() {
        if (!isReadOnly) {
            handleBlur();
        }
    }

    const handleCheckBoxChange = async (checked, id) => {
        const body = {
            [id]: checked,
        };
        await makePatchRequest(`${API_AUDITS.audits}${audit?.id}/`, body);
    };

    return (
        <QuestionsContainer>
            <div className="container">
                {questions.length > 0 && (
                    <>
                        <div>
                            <h1 className="title">Site questions</h1>
                            <div>
                                {questions.map((question, index) => (
                                    <div key={index} className="row">
                                        <div className="col-5 ps-4">
                                            <h2 className="question">{question}</h2>
                                        </div>
                                        <div className="col">
                                            <textarea
                                                value={auditorAnswers[index] || ""}
                                                onChange={(e) =>
                                                    handleInputChange(index, e.target.value)
                                                }
                                                onBlur={handleInputBlur}
                                                disabled={isReadOnly}
                                                maxLength={charLimit}
                                                className="w-100 py-2 px-3"
                                            ></textarea>
                                            {/* <div className="text-muted">
                                            {charCounts[index] || 0}/{charLimit} characters
                                        </div> */}
                                        </div>

                                        <Divider className="my-3" />
                                    </div>
                                ))}
                            </div>
                        </div>
                        <AuditCheckBoxes
                            onCheckBoxChange={(checked, id) => handleCheckBoxChange(checked, id)}
                            audit={audit}
                        />
                    </>
                )}
            </div>
        </QuestionsContainer>
    );
};

AuditQuestions.propTypes = {
    questions: PropTypes.array.isRequired,
    auditorAnswers: PropTypes.array.isRequired,
    handleBlur: PropTypes.func.isRequired,
    handleChange: PropTypes.func.isRequired,
    isReadOnly: PropTypes.bool.isRequired,
};
