import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {DataGrid} from "@mui/x-data-grid";
import {Toolbar, Typography} from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";
import GSPSDataTablePagination from "./DataTablePagination";
import {useTheme} from "styled-components";
import {useIsDesktop} from "Services/ServicesUtils";
import * as TimeZonesUtils from "Services/TimeZonesUtils";

const GSPSDataDrivenTable = ({
    columns = {},
    useQuery = null,
    dataParser = {},
    checkboxSelection = false,
    title = "",
    tableAdminBtn = null,
    defaultPageSize = 25,
    disableColumnResize = false,
    disableColumnMenu = false,
    uniqueTableID,
    rowHeight,
}) => {
    const [currentPageIndex, setCurrentPageIndex] = useState(1);
    const [currentPageSize, setCurrentPageSize] = useState(defaultPageSize);
    const [filters, setFilters] = useState([]);


    const paginationModel = {
        page: currentPageIndex - 1,
        pageSize: currentPageSize,
    };
    const theme = useTheme();
    const isDesktop = useIsDesktop();

    const {data, totalRowCount, isLoading, refetch} = useQuery({
        page: currentPageIndex,
        pageSize: currentPageSize,
        filters
    }, {
        selectFromResult: ({data, isLoading, refetch}) => ({
            data: data?.data || [],
            totalRowCount: data?.totalRowCount || 0,
            isLoading,
            refetch,
        }),
    });

    const getColumns = React.useMemo(
        () => Object.entries(columns).map(([title, key]) => {
            return {
                id: key,
                Header: title,
                accessor: key,
            };
        }),
        [columns, dataParser],
    );

    // Map columns to work with MUI table
    const mapColumns = (columns) => {
        return columns.map((col) => {
            switch (col.Header) {
                case "Actions":
                    if (col.id !== "btn") {
                        return {
                            field: col.id,
                            width: 39,
                            type: "actions",
                            renderCell: dataParser[col.id],
                        };
                    } else {
                        return {
                            field: col.id,
                            headerName: col.Header,
                            ...(isDesktop ? {flex: 1} : {width: 200}),
                            renderCell: dataParser[col.id],
                            sortable: false,
                            filterable: false,
                            disableColumnMenu: true
                        };
                    }
                case "Status":
                    return {
                        field: col.id,
                        headerName: col.Header,
                        ...(isDesktop ? {flex: 1} : {width: 200}),
                        renderCell: (params) => {
                            const status = params.row.is_active ? "Enabled" : "Disabled";
                            const color = status === "Enabled" ? "#2FC0CF" : "#EB4B53";
                            return (
                                <div>
                                    <CircleIcon size="small" style={{color: color}} className="me-2" />
                                    <span>
                                        {status}
                                    </span>
                                </div>
                            );
                        },
                        valueGetter: (params) => params ? "Enabled" : "Disabled",
                    };
                case "Audit Status":
                    return {
                        field: col.id,
                        headerName: col.Header,
                        ...(isDesktop ? {flex: 1} : {width: 200}),
                        renderCell: dataParser[col.id],
                        valueGetter: (params) => {
                            return params.name
                        },
                    };

                case "Account Managers":
                    return {
                        field: col.id,
                        headerName: col.Header,
                        ...(isDesktop ? {flex: 1} : {width: 200}),
                        renderCell: (params) => {
                            const accountAdmins = params.formattedValue || [];
                            return <span>{accountAdmins}</span>;
                        },
                        valueGetter: (params) => {
                            const accountAdmins = params || [];
                            if (accountAdmins.length > 1) {
                                return accountAdmins.map((admin) => admin.full_name).join(", ");
                            }
                            return accountAdmins[0]?.full_name || "-";
                        },
                    };
                case "Address":
                    return {
                        field: col.id,
                        headerName: col.Header,
                        ...(isDesktop ? {flex: 1} : {width: 200}),
                        renderCell: dataParser[col.id],
                        valueGetter: (params) => {
                            return params.full_address || "-";
                        },
                    };
                case "Created on":
                    return {
                        field: col.id,
                        headerName: col.Header,
                        ...(isDesktop ? {flex: 1} : {width: 200}),
                        valueGetter: (params) => {
                            return TimeZonesUtils.convertToEst((params.toString()), true)
                        },
                    };

                default:
                    return {
                        field: col.id,
                        headerName: col.Header,
                        ...(isDesktop ? {flex: 1} : {width: 200}),
                        renderCell: dataParser[col.id],
                        valueGetter: (params) => params ? params : "-",
                    };
            }
        });
    };

    const mappedColumns = mapColumns(getColumns);

    const parsedRows = React.useMemo(() => {
        const getNestedValue = (obj, accessor) => {
            return accessor.split(".").reduce((value, key) => {
                if (value && typeof value === "object") {
                    return value[key];
                }
                return undefined;
            }, obj);
        };

        return data.map((row, index) => {
            const parsedRow = {id: row.id};
            getColumns.forEach((col) => {
                parsedRow[col.id] = getNestedValue(row, col.accessor) === "" || getNestedValue(row, col.accessor) === null ? "-" : getNestedValue(row, col.accessor);
            });
            return parsedRow;
        });
    }, [data, getColumns]);

    const handlePaginationModelChange = (page, pageSize) => {
        setCurrentPageIndex(page + 1);
        setCurrentPageSize(pageSize);
    };

    useEffect(() => {
        refetch();
    }, [refetch]);

    const handleFilterChange = (filterModel) => {
        const filters = filterModel.items.length ? filterModel.items.reduce((acc, item) => {
            acc[item.field] = item.value ? item.value : "";
            return acc;
        }, {}) : {}
        setFilters(filters);
    };


    return (
        <>
            <div className="position-relative my-3">
                <div style={{width: "100%"}}>
                    <Toolbar className="px-3" sx={{backgroundColor: "#FFFFFF", border: "2px solid #E8E7F5", borderTopLeftRadius: "6px", borderTopRightRadius: "6px"}}>
                        <Typography
                            sx={{flex: "1 1 100%", fontWeight: "600"}}
                            variant="h6"
                            id="title"
                            component="div"
                        >
                            {title}
                        </Typography>
                        <div className="tableAdminBtn">
                            {tableAdminBtn}
                        </div>
                    </Toolbar>
                    {
                        <DataGrid
                            rows={parsedRows}
                            columns={mappedColumns}
                            getRowId={uniqueTableID}
                            getRowHeight={() => rowHeight}
                            autoHeight
                            loading={isLoading}
                            rowCount={totalRowCount}
                            disableColumnResize={disableColumnResize}
                            disableColumnMenu={disableColumnMenu}
                            disableRowSelectionOnClick
                            pagination
                            slots={{
                                pagination: GSPSDataTablePagination,
                            }}
                            slotProps={{
                                loadingOverlay: {
                                    variant: "skeleton",
                                    noRowsVariant: "skeleton",
                                },
                            }}
                            pageSizeOptions={[5, 10, 25, 50, 100]}
                            paginationModel={paginationModel}
                            paginationMode="server"
                            filterMode="server" // Server-side filtering
                            onFilterModelChange={handleFilterChange} // Handle filter changes
                            onPaginationModelChange={(e) => handlePaginationModelChange(e.page, e.pageSize)}
                            checkboxSelection={checkboxSelection}
                            sx={
                                {
                                    "border": `2px solid ${theme.color.border}`,
                                    "& .css-yrdy0g-MuiDataGrid-columnHeaderRow": {
                                        backgroundColor: `${theme.color.secondaryBg} !important`,
                                    },
                                    "& .MuiTablePagination-displayedRows": {
                                        marginBottom: "0px !important",
                                    },
                                    "& .MuiTablePagination-selectLabel": {
                                        margin: "0",
                                    },
                                    "& .MuiDataGrid-cell--textLeft a": {
                                        color: "#4CA2A8 !important",
                                        fontSize: "14px",
                                        fontWeight: "400",
                                        textTransform: "none",
                                    },
                                    "& .MuiDataGrid-cell": {
                                        textTransform: "capitalize",
                                    },
                                }}
                        />
                    }
                </div>
            </div>
        </>
    );
};


GSPSDataDrivenTable.propTypes = {
    columns: PropTypes.objectOf(PropTypes.string).isRequired,
    useQuery: PropTypes.func.isRequired,
    dataParser: PropTypes.objectOf(PropTypes.func),
    checkboxSelection: PropTypes.bool,
    title: PropTypes.string,
    tableAdminBtn: PropTypes.node,
    defaultPageSize: PropTypes.number,
    disableColumnResize: PropTypes.bool,
    disableColumnMenu: PropTypes.bool,
    uniqueTableID: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]).isRequired,
    rowHeight: PropTypes.string,
};

export {GSPSDataDrivenTable};
