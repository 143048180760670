import {fetchFile} from "@ffmpeg/util";

import {refreshToken} from "Services/ServicesUtils";
import {fetchBaseQuery} from "@reduxjs/toolkit/query/react";

export const baseQueryWithReauth = (baseUrl) => async (args, api, extraOptions) => {
    let result = await fetchBaseQuery({
        baseUrl,
        prepareHeaders: (headers) => {
            const token = localStorage.getItem("access_token");
            if (token) {
                headers.set("Authorization", `Bearer ${token}`);
            }
            return headers;
        },
    })(args, api, extraOptions);

    if (result.error && result.error.status === 401) {
        try {
            const newAccessToken = await refreshToken();
            result = await fetchBaseQuery({
                baseUrl,
                prepareHeaders: (headers) => {
                    headers.set("Authorization", `Bearer ${newAccessToken}`);
                    return headers;
                },
            })(args, api, extraOptions);
        } catch (error) {
            handleUserLogout();
        }
    }

    return result;
};
export async function compressImage(imageFile) {
    const maxWidth = 800;
    const maxHeight = 600;
    return new Promise((resolve, reject) => {
        const image = new Image();
        const reader = new FileReader();
        reader.onload = (event) => {
            image.onload = () => {
                const canvas = document.createElement("canvas");
                let width = image.width;
                let height = image.height;
                if (width > height) {
                    if (width > maxWidth) {
                        height *= maxWidth / width;
                        width = maxWidth;
                    }
                } else {
                    if (height > maxHeight) {
                        width *= maxHeight / height;
                        height = maxHeight;
                    }
                }
                canvas.width = width;
                canvas.height = height;
                const ctx = canvas.getContext("2d");
                ctx.drawImage(image, 0, 0, width, height);
                canvas.toBlob(
                    (blob) => {
                        const compressedFile = new File([blob], imageFile.name, {type: "image/jpeg"});
                        resolve(compressedFile);
                    },
                    "image/jpeg",
                    0.6,
                );
            };
            image.src = event.target.result;
        };
        reader.onerror = (error) => reject(error);
        reader.readAsDataURL(imageFile);
    });
}
export async function compressVideo(videoFile, ffmpeg, setLoadingProgress) {
    console.log("Compressing video...");

    const progressInterval = setInterval(() => {
        setLoadingProgress((prevProgress) =>
            prevProgress >= 100 ? 0 : prevProgress + 3,
        );
    }, 800);

    await ffmpeg.writeFile("input.mp4", await fetchFile(videoFile));
    console.log("Original video size:", videoFile.size);

    await ffmpeg.exec([
        "-i", "input.mp4",
        "-vcodec", "libx264",
        "-preset", "ultrafast",
        "-crf", "40",
        "-b:v", "500k",
        "-threads", "8",
        "output.mp4",
    ]);

    clearInterval(progressInterval);
    setLoadingProgress(100);

    const data = await ffmpeg.readFile("output.mp4");

    const compressedBlob = new Blob([data.buffer], {type: "video/mp4"});
    console.log("Compressed video size:", compressedBlob.size);
    return new File([compressedBlob], "compressed_output.mp4", {type: "video/mp4"});
}
