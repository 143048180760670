import React, {useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import PropTypes from "prop-types";
import {API_USERS} from "Services/Endpoints";
import {HttpError, makeUnauthenticatedPostRequest} from "Services/ServicesUtils";
import {useNavigate, Link} from "react-router-dom";
import {FormContainer, CustomDivWidth67, LoginForm, LoginFormGroup, GSPSPasswordContainerStyled, GSPSLinkStyled, LoginDataContainerStyled} from "Pages/Login/Login.styles";
import {GSPSErrorMessage} from "Components/Common/GSPSErrorMessage";
import {GSPSLabelledInput, PatternType} from "Components/Common/GSPSLabelledInput";
import {GSPSSubmitButton} from "Components/Common/GSPSSubmitButton";
import {AutheniticationBanner} from "Pages/Login/AuthenticationBanner";
import {useCurrentUser} from "Models/User/useCurrentUser";

export const FAILED_LOGIN_TEXT = "Email & Password combination does not match, or user inactive.";
export const SUBSCRIPTION_INACTIVE_TEXT = "You do not have a valid subscription.";

export const Login = ({setHasValidRefreshToken}) => {
    const {handleSubmit, register, formState: {isDirty, touched, errors}} = useForm();
    const [isUserAuthenticated, setIsUserAuthenticated] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [wasSubmissionFailed, setWasSubmissionFailed] = useState({"failed": false, "message": ""});
    const navigate = useNavigate();
    const currentUser = useCurrentUser();

    useEffect(() => {
        if (!isUserAuthenticated) {
            navigate("/login");
        } else {
            currentUser.resetUser();
            navigate("/dashboard");
        }
    }, [isUserAuthenticated]);

    const onSubmit = (values) => {
        setIsSubmitting(true);
        const body = {
            ...values,
            email: values.email.toLowerCase(),
        };
        makeUnauthenticatedPostRequest(API_USERS.login, body)
            .then((jsonRes) => {
                if (jsonRes.refresh) {
                    const accessToken = jsonRes.access;
                    const refreshToken = jsonRes.refresh;
                    localStorage.setItem("access_token", accessToken);
                    localStorage.setItem("refresh_token", refreshToken);
                    setIsUserAuthenticated(true);
                    setHasValidRefreshToken(true);
                }
            }).catch((e) => {
                if (e instanceof HttpError) {
                    if (e.status === 403) {
                        setWasSubmissionFailed({"failed": true, "message": SUBSCRIPTION_INACTIVE_TEXT});
                    } else {
                        console.error("invalid email/password");
                        setWasSubmissionFailed({"failed": true, "message": FAILED_LOGIN_TEXT});
                    }
                }
            },
            ).finally(() => {
                setIsSubmitting(false);
            });
    };

    return (
        <div className="container d-flex justify-content-center align-items-center vh-100">
            <div style={{width: "600px", borderRadius: "40px", backgroundColor: "white"}}>
                <AutheniticationBanner header={"Log in to your account"} text={"Welcome back! Please enter your details."}></AutheniticationBanner>
                <FormContainer>
                    <CustomDivWidth67>
                        <LoginForm onSubmit={handleSubmit(onSubmit)} noValidate>
                            <LoginDataContainerStyled>
                                <LoginFormGroup>
                                    <GSPSLabelledInput
                                        id="email"
                                        controlType={"input"}
                                        labelName={"Email"}
                                        isRequired={true}
                                        patternType={PatternType.EMAIL}
                                        register={register}
                                        errors={errors} />
                                </LoginFormGroup>
                                <LoginFormGroup>
                                    <GSPSLabelledInput
                                        id="password"
                                        controlType={"password"}
                                        labelName={"Password"}
                                        isRequired={true}
                                        register={register}
                                        errors={errors} />
                                </LoginFormGroup>
                                {wasSubmissionFailed.failed &&
                                    <GSPSErrorMessage messageText={wasSubmissionFailed.message} />}
                                <GSPSPasswordContainerStyled>
                                    <GSPSLinkStyled
                                        to="/forgot-password/"
                                        style={{paddingLeft: "0px"}}>Forgot password
                                    </GSPSLinkStyled>
                                </GSPSPasswordContainerStyled>
                            </LoginDataContainerStyled>
                            <GSPSSubmitButton
                                isDisabled={(!isDirty && touched) || errors.email?.message || isSubmitting}
                                isLoading = {isSubmitting}
                                controlSize={12}
                                offset={0}
                                className="flex-none"
                                buttonText={"Sign In"}>
                            </GSPSSubmitButton>
                        </LoginForm>
                    </CustomDivWidth67>
                </FormContainer>
            </div>
        </div>
    );
};

Login.propTypes = {
    setHasValidRefreshToken: PropTypes.func.isRequired,
};
