import styled from "styled-components";
import {Row, Modal} from "react-bootstrap";

export const StyledLogoUploadContainer = styled.div`
    display: flex;
    gap: 20px;
    width: max-content;
`

export const GSPSPageWrapper = styled(Modal)(({theme}) => ({
    '& .modal': {
        width: "100%"
    },
    '& .modal-lg': {
        width: '770px'
    },
    '@media (max-width: 576px)': {
        '& .modal-lg': {
            width: 'unset'
        },
    },
}));


export const GSPSInputContainer = styled.div`
    padding-left: 15px;
    padding-right: 15px;
`;

export const GSPSEnableDisabledContainer = styled.div`
    display: flex;
    gap: 20px;
    align-items: center;
    margin: 15px 0px 15px 0px;
`;

export const GSPSPopUpHeader = styled.div`
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: -0.25px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 20px 64px 20px 20px;
    isolation: isolate;
    height: 64px;
    background-color: ${({theme}) => theme.color.headerFooterlight};
    border-bottom: 1px solid ${({ theme }) => theme.color.headerFooterBorderlight};
    margin-bottom: 10px;
`

export const GSPSPopUpFooter = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    height: 76px;
    margin-top: 10px;
    gap: 12px;
    padding-right: 12px;
    background: ${({theme}) => theme.color.headerFooterlight};
    border-top: 1px solid ${({theme}) => theme.color.headerFooterBorderlight};
`

export const GSPSStyledRow = styled(Row)`
    gap: 60px;
}
`

