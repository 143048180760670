import React from "react";
import PropTypes from "prop-types";
import {AuditTable} from "./AuditTable";

export const AuditMapSelectedIssues = ({
    selectedIssues,
    deleteIssueCall,
    editIssueCall,
    updateElementImages,
    updateElementVideos,
    isViewOnly = true,
    audit,
}) => {
    const IndexedIssues = selectedIssues.map((issue, index) => {
        return {
            ...issue,
            index: index + 1,
        };
    });
    return (
        <AuditTable
            audit={audit}
            headerName={"Items"}
            isViewOnly={isViewOnly}
            items={IndexedIssues}
            elementType={"issue"}
            updateElementImages={updateElementImages}
            updateElementVideos={updateElementVideos}
            onDelete={deleteIssueCall}
            onEdit={editIssueCall}
            dimensionHeader="Dimension"
        />
    );
};

AuditMapSelectedIssues.propTypes = {
    audit: PropTypes.object,
    deleteIssueCall: PropTypes.func,
    editIssueCall: PropTypes.func,
    selectedIssues: PropTypes.array,
    updateElementImages: PropTypes.func,
    updateElementVideos: PropTypes.func,
    isViewOnly: PropTypes.bool,
};
