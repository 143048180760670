import styled from "styled-components";
import {Container} from "react-bootstrap";
import {Form} from "react-bootstrap";

export const GSPSContainer = styled(Container)`
    @media (min-width: 580px) {
        display: grid;
        gap: 4px;
    }
    padding: 0px;
`;

export const RequiredFormFieldIcon = styled.span`
    color: ${({theme}) => theme.color.danger} !important;
`;

export const GSPSLabelStyledGrey = styled(Form.Label)`
    font-family: 'Roboto Flex';
    font-style: normal;
    font-weight: 500 !important;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: -0.25px;
    color: #717171;
`;

export const GSPSLabelStyledBlack = styled(Form.Label)`
    font-family: 'Roboto Flex';
    font-style: normal;
    font-weight: 500 !important;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: -0.25px;
    color: #121212;
`;

export const StyledRemoveImageLabel = styled.label`
    font-family: 'Roboto Flex';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    text-decoration-line: underline;
    color: #E16E75;
`;

export const StyledUploadImageLabel = styled.label`
    font-family: 'Roboto Flex';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    text-decoration-line: underline;
    color: ${({ theme }) => theme.color.primaryHover} !important; 

/*    &:hover {
        color: ${({ theme }) => theme.color.primaryHover} !important; 
    }*/
`

export const StyledLabel = styled.label`
    font-family: 'Roboto Flex';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: ${({ theme }) => theme.color.labelText}; 
`