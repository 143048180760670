import React, {useContext, useEffect, useState} from "react";
import {AuditMap} from "Pages/Audits/AuditMap";
import {useForm} from "react-hook-form";
import {
    fetchSelectionOptions,
    makeGetRequest,
    makeUpdateRequest,
    makeDeleteRequest,
} from "Services/ServicesUtils";
import {API_AUDITS} from "Models/Audit/AuditEndpoints";
import {insertNotification} from "Utils/NotificationsUtils";
import {useNavigate, useSearchParams} from "react-router-dom";
import {API_USERS} from "Services/Endpoints";
import {AuditorInformation} from "Pages/Audits/AuditorInformation";
import {UserPermissions} from "Models/User/UserUtils";
import {ReviewerInformation} from "Pages/Audits/AuditReviewer";
import {AuditQuestions} from "Pages/Audits/AuditQuestions";
import {UserContext} from "App";
import GSPSBreadCrumbs from "Components/Common/GSPSBreadCrumbs";
import {AuditContainer} from "./AuditCreator.styles";
import AuditGeneralInfo from "./AuditGeneralInfo";
import {Backdrop, CircularProgress, Divider} from "@mui/material";

const flattenAndUnique = (array, key) => {
    const flattened = array.flat();
    const map = new Map(flattened.map((item) => [item[key], item]));
    return Array.from(map.values());
};

export const AuditCreator = () => {
    const {handleSubmit, register, getValues, reset, setValue, formState: {errors}} = useForm();
    const navigate = useNavigate();
    const [currentLocation, setCurrentLocation] = useState(null);
    const [auditTypes, setAuditTypes] = useState([]);
    const [location, setLocation] = useState();
    const [currentAuditType, setCurrentAuditType] = useState();
    const [users, setUsers] = useState();
    const [searchParams] = useSearchParams();
    const locationId = searchParams.get("locationId");
    const [openKey, setOpenKey] = useState("1");
    const [audit, setAudit] = useState();
    const [loading, setLoading] = useState(false);

    const {user} = useContext(UserContext);
    const auditId = searchParams.get("auditId");
    const canReview = UserPermissions.useIsSuperAdmin() || UserPermissions.useIsAdmin();
    const [allQuestions, setAllQuestions] = useState([]);

    const [questions, setQuestions] = useState([]);
    const [filteredQuestions, setFilteredQuestions] = useState(() => flattenAndUnique(questions, "id"));

    const [fetchedAnswers, setFetchedAnswers] = useState([]);
    const [auditorAnswers, setauditorAnswers] = useState(Array(questions.length).fill(""));
    const [answerObjects, setAnswerObjects] = useState([]);

    const [types, setTypes] = useState([]);

    useEffect(() => {
        const filteredAnswers = auditorAnswers.reduce((filteredAnswers, answerText, index) => {
            if (typeof answerText === "string") {
                if (answerText.trim() === "") {
                    answerText = " ";
                }
                filteredAnswers.push({
                    answer_text: answerText,
                    question: filteredQuestions[index].id,
                });
            }
            return filteredAnswers;
        }, []);
        setAnswerObjects(filteredAnswers);
    }, [auditorAnswers]);


    const addType = (newType) => {
        setTypes((prevTypes) => {
            const updatedTypes = [...prevTypes, newType];
            return updatedTypes;
        });
        showQuestions(newType);
    };

    const removeType = (typeToRemove) => {
        setTypes((prevTypes) => {
            const indexToRemove = prevTypes.indexOf(typeToRemove);
            if (indexToRemove !== -1) {
                const updatedTypes = [...prevTypes];
                updatedTypes.splice(indexToRemove, 1);
                return updatedTypes;
            }
            return prevTypes;
        });

        setQuestions((prevQuestions) => {
            const indexToRemove = types.indexOf(typeToRemove);
            if (indexToRemove !== -1) {
                const updatedQ = [...prevQuestions];
                updatedQ.splice(indexToRemove, 1);
                return updatedQ;
            }
            return prevQuestions;
        });
    };


    const fetchAllQuestions = async () => {
        try {
            const allQuestions = await makeGetRequest(API_AUDITS.questions);
            setAllQuestions(allQuestions);
            return allQuestions;
        } catch (error) {
            insertNotification("Error", `Error Fetching questions. Reason: ${error.message}`, "error");
        }
    };

    const handleAuditorAnswerChange = (index, value) => {
        const updatedTempAnswers = [...auditorAnswers];
        updatedTempAnswers[index] = value;
        setauditorAnswers(updatedTempAnswers);
    };

    const handleInputBlur = async () => {
        const fv = getValues();
        try {
            const newAudit = populateAudit(fv);
            const updatedAudit = {...newAudit};
            await makeUpdateRequest(`${API_AUDITS.audits}${audit.id}/`, updatedAudit);
        } catch (e) {
            insertNotification("Error", `Can't update audit's answers: ${e}`, "error");
        }
    };

    const showQuestions = (newtype) => {
        const filteredquestions = allQuestions
            .filter((item) => item.audit_types.some((number) => number.id === newtype))
            .map((item) => ({
                id: item.id,
                text: item.question_text,
            }));
        setQuestions((prevQuestions) => [...prevQuestions, filteredquestions]);
    };

    useEffect(() => {
        const filteredquestions = flattenAndUnique(questions, "id");
        const updatedTempAnswers = [...auditorAnswers];
        async function fetchData() {
            if (filteredquestions.length >= filteredQuestions.length) {
                fetchedAnswers.forEach((ans) => {
                    const index = filteredquestions.findIndex((item) => item.id === ans.question);
                    if (index !== -1) {
                        updatedTempAnswers[index] = ans.answer_text;
                    }
                });
            } else {
                const removedQ = filteredQuestions.filter((item) => !filteredquestions.includes(item));
                const removedIds = removedQ.map((item) => item.id);
                if (removedIds.length > 0) {
                    const idsString = removedIds.join(",");
                    const indicesArray = [];
                    filteredQuestions.forEach((item, index) => {
                        if (!filteredquestions.includes(item)) {
                            indicesArray.push(index);
                        }
                    });
                    try {
                        await makeDeleteRequest(`${API_AUDITS.audits}${auditId}/questions/${idsString}/answer/`);
                        indicesArray.forEach((id) => {
                            updatedTempAnswers.splice(id, 1);
                        });
                    } catch (error) {
                        console.error("Error deleting answers.", error);
                    }
                }
            }
            setauditorAnswers(updatedTempAnswers);
            setFilteredQuestions(filteredquestions);
        }
        fetchData();
    }, [questions, fetchedAnswers]);

    useEffect(() => {
        if (auditId) {
            makeGetRequest(`${API_AUDITS.audits}${auditId}/`).then(
                (res) => {
                    const ans = res.answers;
                    delete res.answers;
                    setAudit(res);
                    const updateCurrentPos = {
                        lat: parseFloat(res?.location?.address?.latitude),
                        lng: parseFloat(res?.location?.address?.longitude),
                    };
                    setCurrentLocation(updateCurrentPos);
                    setLocation(res.location);
                    reset(res.location);

                    setFetchedAnswers(ans);
                });
        }
    }, [auditId]);

    useEffect(() => {
        fetchAllQuestions();
        if (canReview) {
            makeGetRequest(`${API_USERS.users}?no_page`).then(
                (res) => {
                    setUsers(res);
                },
            );
        }
    }, [canReview]);

    useEffect(() => {
        fetchSelectionOptions(API_AUDITS.auditsTypes, setAuditTypes);
    }, []);

    useEffect(() => {
        if (auditTypes.length > 0) {
            setCurrentAuditType(auditTypes[0]);
        }
    }, [auditTypes]);

    const populateAudit = (values) => {
        const location_lnglat = {
            "lat": values.address.latitude ?? audit?.location?.address?.latitude,
            "lng": values.address.longitude ?? audit?.location?.address?.longitude,
        };
        return {
            name: values["audit_name"] ?? audit?.name,
            audit_status_id: 1,
            audit_type_id: currentAuditType.value,
            address_lnglat: location_lnglat,
            answers: answerObjects,
            location: {
                id: locationId,
                account_id: values?.location_account_id ?? audit?.location?.account?.id,
                project_id: values.project_id ?? audit?.project_id,
                location: values.location ?? audit?.name,
                status_type: values.status_type ?? audit?.status_type,
                address: {
                    latitude: location_lnglat.lat,
                    longitude: location_lnglat.lng,
                    place_api_id: values.address.place_api_id ?? audit?.location?.address?.place_api_id,
                    apt_num: values.address.apt_num ?? audit?.location?.address?.apt_num,
                    city: values.address.city ?? audit?.location?.address?.city,
                    state: values.address.state ?? audit?.location?.address?.state,
                    street_name: values.address.street_name ?? audit?.location?.address?.street_name,
                    street_num: values.address.street_num ?? audit?.location?.address?.street_num,
                    zip: values.address.zip ?? audit?.location?.address?.zip,
                },
            },
        };
    };

    return (
        <>
            <div className="Audit">
                <AuditContainer>
                    <GSPSBreadCrumbs staticCrumb={`Audit: ${audit ? audit.name : "Create Audit"}`} />
                    <div className="container">
                        <div className="intro">
                            <h1 className="mainTitle mb-0">Audit: {audit ? audit.name : "Create Audit"}</h1>
                            <h6 className="subTitle">Audit Details</h6>
                        </div>
                        <AuditGeneralInfo
                            location={location}
                            audit={audit}
                            register={register}
                            errors={errors}
                            setValue={setValue}
                            setLoading={setLoading}
                            setCurrentLocation={setCurrentLocation}
                        />

                        {currentAuditType && audit ? (
                            <div data-testid={"map-test-id"} className={"justify-content-center"}>
                                <AuditMap
                                    audit={audit}
                                    currentLocation={currentLocation}
                                    auditType={currentAuditType}
                                    addType={addType}
                                    questions={allQuestions}
                                    removeType={removeType}
                                />
                            </div>
                        ) : (
                            <div>
                                <p>Please save the audit in order to add auditor.</p>
                            </div>
                        )}
                        {audit &&
                            (<AuditQuestions
                                questions={filteredQuestions.map((item) => item.text)}
                                auditorAnswers={auditorAnswers}
                                handleChange={handleAuditorAnswerChange}
                                handleBlur={handleInputBlur}
                                isReadOnly={false}
                                audit={audit}
                            />)
                        }

                        <Divider className="my-4" />

                        <AuditorInformation
                            audit={audit}
                            isReadOnly={false}
                            accordionKey={3}
                            user={user}
                            setOpenKey={setOpenKey}
                        />
                        {
                            canReview && <>
                                <Divider className="my-4" />
                                <ReviewerInformation
                                    audit={audit}
                                    users={users}
                                    readonly={false}
                                />
                            </>
                        }
                    </div>
                    <Backdrop
                        sx={{color: "#fff", zIndex: "555"}}
                        open={loading}
                        onClick={() => setLoading(false)}
                    >
                        <CircularProgress color="inherit" />
                    </Backdrop>
                </AuditContainer>
            </div>
        </>);
};
