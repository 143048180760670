import styled from "styled-components";

export const GSPSBreadCrumbsContainer = styled.div`
    background: ${({theme}) => theme.color.secondaryBg};
    border-bottom: 1px solid ${({theme}) => theme.color.border};
    border-top: 1px solid ${({theme}) => theme.color.border};
    
    & a{
        text-decoration: none;
    }
`;

export const LinkContainer = styled.div`
    color: ${({theme}) => theme.color.dark};
    transition: all ease-in-out 0.3s;
    text-transform: capitalize;

    &:hover {
        color: ${({theme}) => theme.color.info};
    }
    `;
export const HomeCrumb = styled.div`
    color: ${({theme}) => theme.color.secondaryText};
    transition: all ease-in-out 0.3s;

    &:hover {
    color: ${({theme}) => theme.color.info};
    }
`;

export const ActiveCrumb = styled.div`
    color: ${({theme}) => theme.color.dark};
    text-transform: capitalize;
    font-weight: 500;
`;
