import React from "react";
import {useNavigate} from "react-router-dom";
import {Locations} from "Pages/Location/Locations";
import {useCurrentUser} from "Models/User/useCurrentUser";
import {GSPSSubmitButton} from "Components/Common/GSPSSubmitButton";
import {UserPermissions} from "Models/User/UserUtils";

export const LocationsManager = () => {
    const navigate = useNavigate();
    const currentUser = useCurrentUser();
    const isAdmin = UserPermissions.useIsAdmin()

    const routeChange = () =>{
        const path = "/locations/add";
        navigate(path);
    };
    return (
        <>
            <h2> Manage Locations </h2>
            {isAdmin && 
                <GSPSSubmitButton
                    controlSize={5}
                    buttonText={"Add new location"}
                    offset={5}
                    onClick={() => routeChange()}
                />
            }

            {currentUser && currentUser.subscriber &&
                <Locations />
            }

        </>
    );
};
