import jwtDecode from "jwt-decode";
import {makeGetRequest} from "Services/ServicesUtils";
import {API_USERS} from "Services/Endpoints";
import {useContext} from "react";
import {UserContext} from "App";
import {UserPermissionGroupIDs} from "Models/User/UserConstants";

export const fetchCurrentUser = (setCurrentUser) => {
    const token = localStorage.getItem("refresh_token");
    if (!token || token === "undefined") {
        setCurrentUser({});
        return;
    }

    const decoded = jwtDecode(token);
    makeGetRequest(`${API_USERS.users}${decoded.user_id}/`)
        .then((res) => {
            setCurrentUser(res);
        },
        )
        .catch(() => {
            localStorage.removeItem("access_token");
            localStorage.removeItem("refresh_token");
        });
};

const generateUserPermissionHook = (userGroups) => () => {
    const {user} = useContext(UserContext);
    return userGroups.includes(user.group_permission?.id);
};

export const UserPermissions = {
    useIsSuperAdmin: generateUserPermissionHook([UserPermissionGroupIDs.SUPER_ADMIN]),
    useIsGroupAdmin: generateUserPermissionHook([UserPermissionGroupIDs.GROUP_ADMIN]),
    useIsAdmin: generateUserPermissionHook([UserPermissionGroupIDs.GROUP_ADMIN, UserPermissionGroupIDs.SUPER_ADMIN]),
};
