import React, {useEffect, useState} from "react";
import Modal from "react-bootstrap/Modal";
import {useForm} from "react-hook-form";
import {useParams} from "react-router-dom";
import {GSPSLabelledInput, PatternType} from "Components/Common/GSPSLabelledInput";
import {makePostRequest} from "Services/ServicesUtils";
import {API_AUDITS} from "Models/Audit/AuditEndpoints";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlusCircle, faTrash} from "@fortawesome/free-solid-svg-icons";
import PropTypes from "prop-types";

export const EmailPopup = ({defaultEmail}) => {
    const {register, handleSubmit, formState: {errors}, setValue, getValues} = useForm();
    const [emails, setEmails] = useState(defaultEmail ? [defaultEmail] : []);
    const [message, setMessage] = useState("");
    const [error, setError] = useState("");
    const [isPreviewDisplayed, setIsPreviewDisplayed] = useState(true);
    const handleClose = () => setIsPreviewDisplayed(false);
    const {auditID} = useParams();

    const baseurl = window.location.origin;
    const linkToBeSend = `${baseurl}/public-audits/${auditID}/`;

    useEffect(() => {
        setEmails(defaultEmail ? [defaultEmail] : []);
    }, [defaultEmail]);

    const onSubmit = () => {
        if (emails.length === 0) {
            setError("Please add at least one email.");
            return;
        }
        const emailData = {
            emails: emails,
            audit_id: auditID,
            body: `You're invited to access the audit by clicking on the following link: ${linkToBeSend}`,
        };

        makePostRequest(API_AUDITS.AuditEmail, emailData)
            .then(() => {
                setMessage("Emails sent successfully!");
                setError("");
                setTimeout(() => {
                    handleClose();
                }, 1000);
            })
            .catch(() => {
                setError("There was an error sending the emails!");
            })
            .finally(() => {
                setTimeout(() => setMessage(""), 5000);
            });
    };

    const handleAddEmail = (email) => {
        if (PatternType.EMAIL.value.test(email) && !emails.includes(email)) {
            setEmails([...emails, email]);
            setValue("email", "");
            setError("");
        } else {
            setError("Invalid or duplicate email.");
        }
    };
    const handleDeleteEmail = (emailToDelete) => {
        setEmails(emails.filter((email) => email !== emailToDelete));
    };

    const onAddButtonClick = () => {
        const {email} = getValues();
        handleAddEmail(email);
    };
    return (
        <>
            <Modal className="modal show"
                backdrop="static"
                onHide={handleClose}
                show={isPreviewDisplayed}
                centered
            >
                <Modal.Header closeButton><h5>Audit Email</h5></Modal.Header>
                <Modal.Body>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="d-flex align-items-end">
                            <div className="flex-grow-1"
                            >
                                <GSPSLabelledInput
                                    id={"email"}
                                    labelName={"Email"}
                                    controlType={"input"}
                                    placeHolder="Enter Email..."
                                    patternType={PatternType.EMAIL}
                                    register={register}
                                    errors={errors}
                                    onKeyDown={(e) => {
                                        if (e.key === "Enter") {
                                            e.preventDefault();
                                            if (!e.target.value) return;
                                            handleAddEmail(e.target.value);
                                        }
                                    }}
                                />

                            </div>
                            <button id={"add-button"}type="button" className="btn btn-secondary rounded" onClick={onAddButtonClick}>
                                <FontAwesomeIcon
                                    icon={faPlusCircle}
                                />
                            </button>
                        </div>
                        <div className="my-1">
                            {message && <div className="alert alert-success">{message}</div>}
                            {error && <div className="alert alert-danger">{error}</div>}
                        </div>
                        <div className="d-flex flex-wrap my-3">
                            {emails.map((email, index) => (
                                <div key={index} className="bg-light p-2 m-1 d-flex align-items-center rounded" style={{width: "fit-content"}}>
                                    {email}
                                    <FontAwesomeIcon
                                        icon={faTrash}
                                        onClick={() => handleDeleteEmail(email)}
                                        className="ms-2 text-danger"
                                        style={{cursor: "pointer"}}
                                    />
                                </div>
                            ))}
                        </div>
                        <Modal.Footer >
                            <button id="submit-btn" type="submit" className="btn btn-primary">Send</button>
                        </Modal.Footer>
                    </form>
                </Modal.Body>
            </Modal>

        </>
    );
};

EmailPopup.propTypes = {
    defaultEmail: PropTypes.string,
};
