import {createApi} from "@reduxjs/toolkit/query/react";
import {API_LOCATIONS} from "Models/Location/LocationEndpoints";
import {baseQueryWithReauth} from "Utils/helpers";

export const locationApi = createApi({
    reducerPath: "locationApi",
    baseQuery: baseQueryWithReauth(API_LOCATIONS.locations),
    tagTypes: ["Location"],
    endpoints: (builder) => ({
        fetchLocations: builder.query({
            query: ({page = 1, pageSize = 10, filters = {}}) => {
                const filterParams = new URLSearchParams({
                    page,
                    page_size: pageSize,
                    ...Object.fromEntries(
                        Object.entries(filters).map(([key, value]) => [key, `${value}`])
                    ),
                }).toString();
                return `?${filterParams}`;
            },
            transformResponse: (response) => ({
                data: response.results,
                totalRowCount: response.count,
            }),
            providesTags: (result) =>
                result ? [...result.data.map(({id}) => ({type: "Location", id})), "Location"] : ["Location"],
            keepUnusedDataFor: 60,
        }),
        addLocation: builder.mutation({
            query: (newLocation) => ({
                url: "/",
                method: "POST",
                body: newLocation,
            }),
            invalidatesTags: ["Location"],
        }),
        updateLocation: builder.mutation({
            query: ({id, ...updatedLocation}) => ({
                url: `/${id}/`,
                method: "PUT",
                body: updatedLocation,
            }),
            invalidatesTags: (result, error, {id}) => [{type: "Location", id}],
        }),
    }),
});

export const {
    useFetchLocationsQuery,
    useAddLocationMutation,
    useUpdateLocationMutation,
} = locationApi;
