import {createApi} from "@reduxjs/toolkit/query/react";
import {API_USERS} from "Services/Endpoints";
import {baseQueryWithReauth} from "Utils/helpers";

export const userApi = createApi({
    reducerPath: "userApi",
    baseQuery: baseQueryWithReauth(API_USERS.users),
    tagTypes: ["User"],
    endpoints: (builder) => ({
        fetchUsers: builder.query({
            query: ({page = 1, pageSize = 10, filters = {}}) => {
                // Convert filters object to query string
                const filterParams = new URLSearchParams({
                    page,
                    page_size: pageSize,
                    ...Object.fromEntries(
                        Object.entries(filters).map(([key, value]) => [key, `${value}`])
                    ),
                }).toString();
                return `?${filterParams}`;
            },
            transformResponse: (response) => ({
                data: response.results,
                totalRowCount: response.count,
            }),
            providesTags: (result) =>
                result
                    ? [...result.data.map(({id}) => ({type: "User", id})), "User"]
                    : ["User"],
            keepUnusedDataFor: 60,
        }),
        addUser: builder.mutation({
            query: (newUser) => ({
                url: "/",
                method: "POST",
                body: newUser,
            }),
            invalidatesTags: ["User"],
        }),
        updateUser: builder.mutation({
            query: ({id, ...updatedUser}) => ({
                url: `/${id}/`,
                method: "PUT",
                body: updatedUser,
            }),
            invalidatesTags: (result, error, {id}) => [{type: "User", id}],
        }),
    }),
});

export const {
    useFetchUsersQuery,
    useAddUserMutation,
    useUpdateUserMutation,
} = userApi;
