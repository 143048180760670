import styled from "styled-components";

export const AuditContainer = styled.div`
    .intro .mainTitle{
        color: ${({theme}) => theme.color.textOnBg};
        font-size: 24px;
        font-weight: 600;
        letter-spacing: -0.5px;
    }
    .intro .subTitle{
        font-size: 12px;
        font-weight: 400;
        opacity: 0.5;
    }
    
    button[type="submit"], button[type="button"]{
        color: ${({theme}) => theme.color.dark} !important;
        text-transform: capitalize;
    }

    .MuiButton-contained{
        background-color: ${({theme}) => theme.color.info} !important;
    }
    .MuiButton-outlined{
        border-color: ${({theme}) => theme.color.info} !important;
    }

    .title{
        color: ${({theme}) => theme.color.textOnBg};
        font-size: 16px;
        font-weight: 600;
        letter-spacing: -0.25px;
    }
    textarea{
        resize: none;
        height: 150px;
        border: 1px solid ${({theme}) => theme.color.border};
    }
        
    .auditTable .header{
        background: ${({theme}) => theme.color.border};
    }
    .auditTable .MuiDataGrid-cell {
        display: flex;
        justify-content: start;
        align-items: center;
    }
`