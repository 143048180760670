import styled from "styled-components";

export const QuestionsContainer = styled.div`
    .question{
        color: ${({theme}) => theme.color.textOnBg};
        font-size: 14px;
        font-weight: 400;
    }
    textarea{
        height: 80px;
        outline: none;
        border-radius: 3px;
    }
    textarea:focus, textarea:hover{
        border: 1px solid ${({theme}) => theme.color.info} !important;
    }

    textarea[disabled], textarea[readonly] {
        background-color: ${({ theme }) => theme.color.disabledBg || '#EBEFEE'};
        color: ${({ theme }) => theme.color.disabledText || '#000000'};
        border: 1px dashed #E8E7F5;
        opacity: 1;
        cursor: not-allowed;
    }
`;