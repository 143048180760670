import React from "react";
import PropTypes from "prop-types";
import {HeaderTitle, HeaderDescription} from "./AuthenticationBanner.style";

export const AutheniticationBanner = ({header, text}) => {
    return (
        <div className="d-flex flex-column justify-content-center align-items-center" >
            <img src="/SVP_light_bg.png" style={{width: "431px", height: "82px"}}
                className="mt-5"
            ></img>
            {/* <img src="/SVP_dark_bg.png" style={{width: "431px", height: "82px"}}
                className="mt-5"
            ></img> */}
            <HeaderTitle className="mt-5">{header}</HeaderTitle>
            {text.length > 0 && 
                <HeaderDescription>{text}</HeaderDescription>
            }
            
        </div>)
    ;
};

AutheniticationBanner.propTypes = {
    header: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
};
