import React, {useEffect} from "react";
import {Form} from "react-bootstrap";
import PropTypes from "prop-types";
import {GSPSContainer, RequiredFormFieldIcon, GSPSLabelStyledBlack} from "./GSPSLabeledInput.styles";
import {GSPSImageSelector} from "./GSPSImageSelector";

export const GSPSFormImageInput = ({
    id,
    labelName,
    labelSize,
    controlSize,
    isRequired = false,
    imageContainerWidthPx,
    imageContainerHeightPx,
    value,
    setValue,
    currentImageSrc,
    handleDeleteImage,
    setHasLogo
}) => {
    return (
        <GSPSContainer>
            <GSPSLabelStyledBlack column sm={labelSize} htmlFor={id} className="">
                {labelName}: {isRequired && <RequiredFormFieldIcon>* </RequiredFormFieldIcon>}
            </GSPSLabelStyledBlack>
            <GSPSImageSelector
                id={id}
                controlSize={controlSize}
                imageContainerWidthPx={imageContainerWidthPx}
                imageContainerHeightPx={imageContainerHeightPx}
                setValue={setValue}
                value={value}
                currentImageSrc={currentImageSrc}
                handleDeleteImage={handleDeleteImage}
                setHasLogo={setHasLogo}
            />
        </GSPSContainer>
    );
};

GSPSFormImageInput.propTypes = {
    id: PropTypes.string.isRequired,
    labelName: PropTypes.string.isRequired,
    labelSize: PropTypes.number,
    controlSize: PropTypes.number,
    isRequired: PropTypes.bool,
    imageContainerWidthPx: PropTypes.number.isRequired,
    imageContainerHeightPx: PropTypes.number.isRequired,
    value: PropTypes.string,
    setValue: PropTypes.func,
    currentImageSrc: PropTypes.string,
    handleDeleteImage: PropTypes.func,
    setHasLogo: PropTypes.bool,
};
