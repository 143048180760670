import styled from 'styled-components';

export const TopMenuContainer = styled.div`
    background-color: ${({theme}) => theme.color.navBg};
    position: sticky;
    top: 0;
    z-index: 500;

    .logo {
        display: block;
        width: 170px;
    }

    .menuToggler {
        width: 50px;
        height: 100%;
        border: none;
        outline: none;
        box-shadow: none;
        background: none; 
    }

    .menuToggler span {
        display: block;
        height: 2px;
        width: 100%;
        background-color: ${({theme}) => theme.color.light};
        margin: 10px 0;
        transition: transform 0.4s cubic-bezier(0.68, -0.6, 0.32, 1.6), width 0.4s ease-in-out;
        border-radius: 10px;
    }

    .menuToggler span:nth-of-type(1) {
        width: 50%;
    }

    .menuToggler span:nth-of-type(2) {
        width: 100%;
    }

    .menuToggler span:nth-of-type(3) {
        width: 75%;
    }

    .menuToggler:not(.collapsed) span:nth-of-type(1) {
        transform-origin: bottom;
        transform: rotateZ(45deg) translate(5px, 0);
    }

    .menuToggler:not(.collapsed) span:nth-of-type(2) {
        transform-origin: top;
        transform: rotateZ(-45deg);
    }

    .menuToggler:not(.collapsed) span:nth-of-type(3) {
        transform-origin: bottom;
        width: 50%;
        transform: translate(21px, -3px) rotateZ(45deg);
    }

    .collapse.show{
        display: flex;
        flex-direction: column-reverse;
    }

    .navLink {
        font-size: 14px;
        transition: all 0.5s ease-in-out;
        text-decoration: none;
        font-weight: 500;
        position: relative;
        color: ${({theme}) => theme.color.light} !important;
        opacity: 0.5;
        display: flex;
        align-items: center;
        justify-content: center;

        &.setting {
            color: ${({theme}) => theme.color.light} !important;
            transition: all 0.5s ease-in-out;
            opacity: 1;
        }

        &.setting:hover {
            transform: scale(1.3) rotate(-30deg);
        }

        &:not(.setting)::after {
            content: "";
            position: absolute;
            width: 0%;
            bottom: 2px;
            left: auto;
            right: 0;
            height: 1px;
            background-color: ${({theme}) => theme.color.primary};
            transition: all 0.5s ease-in-out;
        }

        &:hover:not(.setting), &.active {
            opacity: 1;
        }

        &:hover::after, &.active::after {
            right: auto;
            left: 0;
            width: 100%;
        }
    }
`;
