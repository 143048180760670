import styled from "styled-components";

export const FilterContainer = styled.div`
    min-width: 440px;
    .title{
        font-size: 14px;
        font-weight: 400;
        color: ${({theme}) => theme.color.textOnBg}
    }
    button{
        color: ${({theme}) => theme.color.info};
        font-size: 12px;
        font-weight: 400;
        text-transform: none;
    }
    button.MuiButton-contained{
        background: ${({theme}) => theme.color.info};
        color: ${({theme}) => theme.color.textOnBg}
    }
    button.MuiButton-outlined{
        border-color: ${({theme}) => theme.color.info};
        color: ${({theme}) => theme.color.textOnBg};
    }
    .MuiCheckbox-root{
        color: #A0A0A0;
    }
    & .Mui-checked, .MuiCheckbox-indeterminate{
        color: ${({theme}) => theme.color.info} !important;
    }
    .childOpt {
        white-space: nowrap; 
        overflow: hidden;    
        text-overflow: ellipsis; 
    }

`