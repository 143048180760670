import React, {useState} from "react";
import {useForm} from "react-hook-form";
import {API_USERS} from "Services/Endpoints";
import {HttpError, makeUnauthenticatedPostRequest} from "Services/ServicesUtils";
import {Row} from "react-bootstrap";
import {FormContainer, CustomDivWidth67, LoginForm, LoginFormGroup, GSPSLinkStyled} from "Pages/Login/Login.styles";
import {GSPSErrorMessage} from "Components/Common/GSPSErrorMessage";
import {GSPSLabelledInput, PatternType} from "Components/Common/GSPSLabelledInput";
import {GSPSSubmitButton} from "Components/Common/GSPSSubmitButton";
import {GSPSSuccessMessage} from "Components/Common/GSPSSuccessMessage.styles";
import {AutheniticationBanner} from "Pages/Login/AuthenticationBanner";

const FAILED_SUBMIT_TEXT = "Failed to submit reset password request. Please check your input and try again later.";
const FORM_SUBMITTED_TEXT = <div><strong>Reset password requested. </strong>
Check your inbox for an email with a link to set your new password. If you have not received an email check you
spam folder and double check the email you submitted</div>;

export const ForgotPassword = () => {
    const {handleSubmit, register, formState: {isDirty, errors, touched}} = useForm();
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [wasSubmissionFailed, setWasSubmissionFailed] = useState(false);


    const onSubmit = (values) => {
        setIsSubmitting(true);
        makeUnauthenticatedPostRequest(API_USERS.forgotPassword, values)
            .then((jsonRes) => {
                setIsFormSubmitted(true);
            }).catch((e) => {
                if (e instanceof HttpError) {
                    console.error(`Failed to reset Password ${JSON.stringify(e.body)}`);
                    setWasSubmissionFailed(true);
                }
            },
            ).finally(() => {
                setIsSubmitting(false);
            });
    };

    return (
        <div className="container d-flex justify-content-center align-items-center vh-100">
            <div style={{width: "600px", borderRadius: "40px", backgroundColor: "white"}}>
                <AutheniticationBanner header={"Forgot password?"} text={"No worries, we'll send you instructions"}></AutheniticationBanner>
                {!isFormSubmitted && <FormContainer>
                    <CustomDivWidth67>
                        <LoginForm onSubmit={handleSubmit(onSubmit)} noValidate>
                            <LoginFormGroup>
                                <GSPSLabelledInput
                                    id="login"
                                    controlType={"input"}
                                    labelName={"Email"}
                                    errors={errors}
                                    isRequired={true}
                                    register={register}
                                    patternType={PatternType.EMAIL}
                                />
                            </LoginFormGroup>
                            {wasSubmissionFailed &&
                            <GSPSErrorMessage messageText={FAILED_SUBMIT_TEXT} />}
                            <LoginFormGroup>
                                <GSPSSubmitButton
                                    isDisabled={(!isDirty && touched) || errors.email?.message || isSubmitting}
                                    isLoading = {isSubmitting}
                                    controlSize={12}
                                    offset={0}
                                    buttonText={"Reset Password"}>
                                </GSPSSubmitButton>
                            </LoginFormGroup>
                            <GSPSLinkStyled
                                to="/login/"
                                style={{paddingLeft: "40%"}}>Back to Log in
                            </GSPSLinkStyled>
                        </LoginForm>
                    </CustomDivWidth67>
                </FormContainer>}
                {isFormSubmitted &&
            <GSPSSuccessMessage >{FORM_SUBMITTED_TEXT}</GSPSSuccessMessage>
                }
            </div>
        </div>
    );
};

ForgotPassword.propTypes = {
};
