import React, {useState, useEffect} from "react";
import PropTypes from "prop-types";
import {AuditImages, AuditVideos} from "Pages/Audits/AuditMedia";
import {Table, TableBody, TableCell, TableHead, TableRow, Toolbar, Tooltip, Typography} from "@mui/material";
import {GSPSDeleteButtonStyle} from "Components/Common/GSPSDeleteButton.styles";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrash} from "@fortawesome/free-solid-svg-icons";
import {insertNotification} from "Utils/NotificationsUtils";
import ConfirmationModal from "Components/Common/ConfirmationModal";
import {DataGrid} from "@mui/x-data-grid";
import CircleIcon from '@mui/icons-material/Circle';
import ImageIcon from '@mui/icons-material/Image';
import VideocamIcon from '@mui/icons-material/Videocam';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import {useTheme} from "styled-components";
import {useIsDesktop} from "Services/ServicesUtils";
import GSPSPropDrivenTable from "Components/DataTable/GSPSPropDrivenTable";

export const AuditTable = ({
    items,
    headerName,
    elementType,
    dimensionHeader,
    isViewOnly = true,
    onDelete,
    onEdit
}) => {
    const [modalShow, setModalShow] = useState(false);
    const [idToDelete, setIdToDelete] = useState(null);
    const [openTooltipIndex, setOpenTooltipIndex] = useState(null);
    const theme = useTheme();
    const isDesktop = useIsDesktop();

    const handleDelete = async () => {
        try {
            await onDelete(idToDelete);
            setModalShow(false);
            setIdToDelete(null);
            insertNotification("Success", "Area has been deleted.", "Success");
        } catch (error) {
            insertNotification("Error", `Can't delete area. Reason: ${error.message}`, "error");
        }
    };

    const columns = [
        {field: 'index', headerName: 'ID', width: 70, headerClassName: 'header'},
        {
            field: 'auditTypeName',
            headerName: 'Audit Type',
            ...(isDesktop ? {flex: 1} : {width: 200}),
            headerClassName: 'header',
            renderCell: (params) => params.row.audit_type_item.audit_type.name,
        },
        {
            field: 'auditTypeItemsName',
            headerName: 'Issues',
            ...(isDesktop ? {flex: 1} : {width: 200}),
            headerClassName: 'header',
            renderCell: (params) => params.row.audit_type_item.name
        },
        ...(elementType === "issue" ? [{
            field: 'severity_object',
            headerName: 'Severity',
            width: 150,
            headerClassName: 'header',
            renderCell: (params) => <span><CircleIcon fontSize="small" className="me-2" sx={{color: params.value.color_code}} /> {params.value.name}</span>
        }] : []),
        {
            field: `${elementType}_images`,
            headerName: 'Image',
            sortable: false,
            ...(isDesktop ? {flex: 3} : {width: 400}),
            headerClassName: 'header',
            renderCell: (params) => {
                const images = params.value;
                if (images && images.length > 0) {
                    return (
                        <div>
                            {images.map((image, index) =>
                                image.image_value ? (
                                    <Tooltip
                                        key={`${params.row.id}-${index}`}
                                        title={<img src={image.image_value} className="img-fluid" alt="full size" />}
                                        disableFocusListener
                                        disableHoverListener
                                        disableTouchListener
                                        open={openTooltipIndex === `${params.row.id}-${index}`}
                                        onClose={() => setOpenTooltipIndex(null)}
                                    >
                                        <button
                                            className="imgContainer border-0 bg-transparent mx-3 my-2"
                                            style={{width: "120px"}}
                                            onClick={() => setOpenTooltipIndex(`${params.row.id}-${index}`)}
                                            onMouseLeave={() => setOpenTooltipIndex(null)}
                                        >
                                            <img src={image.image_value} className="img-fluid rounded-1" alt="audit thumbnail" />
                                        </button>
                                    </Tooltip>
                                ) : null
                            )}
                        </div>
                    );
                } else {
                    return "-";
                }
            }
        },
        {
            field: `${elementType}_videos`,
            headerName: 'Video',
            sortable: false,
            ...(isDesktop ? {flex: 1} : {width: 200}),
            headerClassName: 'header',
            renderCell: (params) => {
                const videos = params.value;
                if (videos && videos.length > 0) {
                    return (
                        <div>
                            {videos.map((video, index) =>
                                video.video_value ? (
                                    <Tooltip
                                        key={`${params.row.id}-video-${index}`}
                                        title={<video src={video.video_value} className="img-fluid" autoPlay loop />}
                                        disableFocusListener
                                        disableHoverListener
                                        disableTouchListener
                                        open={openTooltipIndex === `${params.row.id}-video-${index}`}
                                        onClose={() => setOpenTooltipIndex(null)}
                                    >
                                        <button
                                            className="imgContainer border-0 bg-transparent"
                                            style={{width: "40px", height: "40px"}}
                                            onClick={() => setOpenTooltipIndex(`${params.row.id}-video-${index}`)}
                                            onMouseLeave={() => setOpenTooltipIndex(null)}
                                        >
                                            <VideocamIcon
                                                fontSize="small"
                                                className="me-3"
                                            />
                                        </button>
                                    </Tooltip>
                                ) : null
                            )}
                        </div>
                    );
                } else {
                    return "-";
                }
            }
        },
        {
            field: 'description',
            headerName: 'Description',
            sortable: false,
            width: 200,
            headerClassName: 'header',
            renderCell: (params) => params.value ? params.value : "-"
        },
        ...(elementType !== "issue" ? [{
            field: `${elementType === "line" ? "length" : elementType}_feet`,
            headerName: dimensionHeader,
            width: 150,
            headerClassName: 'header',
            renderCell: (params) => {
                const value = params.value || 0; // default value
                return parseFloat(value).toFixed(2);
            }
        }] : []),
        {
            field: 'notes',
            headerName: 'Notes',
            sortable: false,
            ...(isDesktop ? {flex: 1} : {width: 200}),
            headerClassName: 'header',
            renderCell: (params) => params.value ? params.value : "-"
        },
        ...(!isViewOnly ? [{
            field: 'actions',
            headerName: 'Actions',
            width: 100,
            headerClassName: 'header',
            renderCell: (params) => <div className="actions">
                <button className="border-0 bg-transparent px-2" onClick={() => {
                    setIdToDelete(params.row.id);
                    setModalShow(true);
                }}>
                    <Tooltip title="Delete">
                        <DeleteIcon fontSize="small" sx={{color: theme.color.textOnBg}} />
                    </Tooltip>
                </button>
                <button className="border-0 bg-transparent px-2" onClick={() => onEdit(params.row)}>
                    <Tooltip title="Edit">
                        <EditIcon fontSize="small" sx={{color: theme.color.textOnBg}} />
                    </Tooltip>
                </button>
            </div>
        }] : []),
    ];

    return (
        <div className="auditTable my-5">
            <ConfirmationModal
                show={modalShow}
                onHide={() => setModalShow(false)}
                message={`Are you sure you want to delete this ${elementType}?`}
                onConfirm={handleDelete}
            />

            <GSPSPropDrivenTable
                title={headerName}
                data={items}
                columns={columns}
                disableColumnResize
                disableColumnMenu
                rowHeight="auto"
            />
        </div>
    );
};


AuditTable.propTypes = {
    headerName: PropTypes.string.isRequired,
    dimensionHeader: PropTypes.string.isRequired,
    items: PropTypes.array,
    updateElementImages: PropTypes.func,
    updateElementVideos: PropTypes.func,
    isViewOnly: PropTypes.bool,
    elementType: PropTypes.string,
    onDelete: PropTypes.func,
    onEdit: PropTypes.func
};

