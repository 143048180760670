import {useEffect, useState} from "react";
import {AuditsIntroSectionContainer} from "./AuditsIntroSection.styles"
import CircleIcon from '@mui/icons-material/Circle';
import {pieArcLabelClasses, PieChart} from '@mui/x-charts/PieChart';
import {makeGetRequest} from "Services/ServicesUtils";
import {API_AUDITS} from "Models/Audit/AuditEndpoints";
import PropTypes from "prop-types";


const AuditsIntroSection = ({updateStats}) => {
    const [totalAuditsCreated, setTotalAuditsCreated] = useState(0);
    const [totalAuditsCompleted, setTotalAuditsCompleted] = useState(0);
    const [totalAuditsInReview, setTotalAuditsInReview] = useState(0);

    useEffect(() => {
        makeGetRequest(`${API_AUDITS.stats}`).then(
            (res) => {
                setTotalAuditsCompleted(res.completed);
                setTotalAuditsInReview(res.in_review);
                setTotalAuditsCreated(res.in_progress);
            });
    }, [updateStats])

    return <section className="AuditsIntroSection">
        <AuditsIntroSectionContainer>
            <div className="container py-4">
                <div className="row justify-content-between">
                    <div className="col-12 col-md-9 col-lg-7 px-5">
                        <h1 className="title mb-md-1 mb-4 text-md-start text-center">
                            Audits Overview
                        </h1>
                        <div className="breakdown">
                            <h6 className="title text-center text-md-start mb-2 mb-md-0">
                                Breakdown
                            </h6>
                            <div className="insights d-flex justify-content-between align-items-center flex-md-row flex-column pe-md-5 pe-0 me-md-4 me-0 py-4 py-md-0">
                                <div className="auditsCompleted d-flex flex-column">
                                    <h1 className="updatedNumber mb-md-1 mb-2 text-center text-md-start">
                                        {totalAuditsCompleted}
                                    </h1>
                                    <p className="description d-flex align-items-center">
                                        <CircleIcon className="me-2" fontSize="small" /> Completed
                                    </p>
                                </div>
                                <div className="auditsCreated d-flex flex-column">
                                    <h1 className="updatedNumber mb-md-1 mb-2 text-center text-md-start">
                                        {totalAuditsCreated}
                                    </h1>
                                    <p className="description d-flex align-items-center">
                                        <CircleIcon className="me-2" fontSize="small" /> In Progress
                                    </p>
                                </div>
                                <div className="auditsInReview d-flex flex-column">
                                    <h1 className="updatedNumber mb-md-1 mb-2 text-center text-md-start">
                                        {totalAuditsInReview}
                                    </h1>
                                    <p className="description d-flex align-items-center">
                                        <CircleIcon className="me-2" fontSize="small" /> In Review
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-3 col-lg-3 position-relative d-flex justify-content-end chart mb-5 mb-md-0">
                        <PieChart
                            series={[
                                {
                                    data: [
                                        {value: totalAuditsCreated, color: "rgba(221, 124, 194, 1)", label: "Audits In Progress"},
                                        {value: totalAuditsInReview, color: "rgba(230, 176, 86, 1)", label: "Audits In Review"},
                                        {value: totalAuditsCompleted, color: "rgba(76, 162, 168, 1)", label: "Audits Completed"},
                                    ],
                                    arcLabel: (item) => `${item.value}`,
                                    arcLabelMinAngle: 45,
                                    innerRadius: 55,
                                    highlightScope: {faded: 'global', highlighted: 'item'},
                                    faded: {innerRadius: 30, additionalRadius: -30, color: 'gray'},
                                    startAngle: "-45"
                                },
                                {
                                    data: [
                                        {value: totalAuditsCreated, color: "rgba(221, 124, 194, 0.3)"},
                                        {value: totalAuditsInReview, color: "rgba(230, 176, 86, 0.3)"},
                                        {value: totalAuditsCompleted, color: "rgba(76, 162, 168, 0.3)"},
                                    ],
                                    innerRadius: 25,
                                    outerRadius: 45,
                                    startAngle: "-45"
                                },
                            ]}
                            slotProps={{
                                legend: {hidden: true},
                            }}
                            height={296}
                            width={296}
                            margin={0}

                            sx={{
                                [`& .${pieArcLabelClasses.root}`]: {
                                    fill: 'white',
                                    fontSize: 14,
                                    fontWeight: 600,
                                    letterSpacing: "-0.25px"
                                },
                                [`& .${pieArcLabelClasses.faded}`]: {
                                    fontSize: "0"
                                },
                            }}
                        />
                    </div>

                </div>
            </div>
        </AuditsIntroSectionContainer>
    </section>
}

AuditsIntroSection.propTypes = {
    updateStats: PropTypes.number
}

export default AuditsIntroSection