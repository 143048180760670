import React, {useState} from "react";
import {useTheme} from "styled-components";
import GSPSBreadCrumbs from "Components/Common/GSPSBreadCrumbs";
import {GSPSDataDrivenTable} from "Components/DataTable/GSPSDataDrivenTable";
import {useFetchLocationsQuery} from "api/LocationTableAPI"; import {AddLocation} from "./AddLocation";
import {Location} from "./Location";
import {UserPermissions} from "Models/User/UserUtils";
import {useCurrentUser} from "Models/User/useCurrentUser";
import {GSPSSubmitButtonStyle} from "Components/Common/GSPSSubmitButton.styles";
import {NavLink, useNavigate} from "react-router-dom";
import {Button} from "@mui/material";

export const Locations = () => {
    const [showAddModal, setShowAddModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [locationID, setLocationID] = useState(null);
    const currentUser = useCurrentUser();
    const isAdmin = UserPermissions.useIsAdmin();
    const theme = useTheme();
    const navigate = useNavigate();

    let locationsColumns = {
        "Address": "address",
        "Account": "account.name",
        "Property Type": "account.account_type.name",
        "Account Managers": "account.account_admins",
        "Status": "is_active",
    };

    if (isAdmin) {
        locationsColumns = {
            ...locationsColumns, "Actions": "btn",
        };
    }
    const dataParser = {
        address: (location) => (<NavLink
            onClick={() => {
                setLocationID(location.id);
                setShowEditModal(true);
            }}
        >
            {location.row.address.full_address}
        </NavLink>
        ),
        btn: (location) => <Button variant={"outlined"}
            onClick={() => navigate(`/audits/creator?locationId=${location.id}`)} disabled={!location.row.is_active}
            sx={{
                "color": theme.color.textOnBg,
                "borderColor": theme.color.textOnBg,
                "textTransform": "capitalize",
                "&:hover": {
                    borderColor: theme.color.info,
                },
            }}>
            Audit
        </Button>,
    };

    return (
        <div className="locations">
            <GSPSBreadCrumbs />
            <div className="container">
                {
                    currentUser && currentUser.subscriber &&
                    <GSPSDataDrivenTable
                        columns={locationsColumns}
                        dataParser={dataParser}
                        title="Locations"
                        tableAdminBtn={
                            isAdmin && <GSPSSubmitButtonStyle
                                onClick={() => setShowAddModal(true)}
                            >
                                Add new location
                            </GSPSSubmitButtonStyle>
                        }
                        useQuery={useFetchLocationsQuery}
                    />
                }
            </div>
            {showAddModal && (
                <AddLocation
                    show={showAddModal}
                    setShow={setShowAddModal}
                />
            )}
            {showEditModal && locationID && (
                <Location
                    show={showEditModal}
                    setShow={setShowEditModal}
                    locationID={locationID}
                />
            )}
        </div>
    );
};
